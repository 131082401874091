import { createSetting } from '@shopex/finder'

const ITEM_STATUS_ENUM = {
  1: 'ASP',
  2: '非ASP'
}

// 是否
const REST_DAY_OPTIONS = [
  {label: '周日', value: 0},
  {label: '周一', value: 1},
  {label: '周二', value: 2},
  {label: '周三', value: 3},
  {label: '周四', value: 4},
  {label: '周五', value: 5},
  {label: '周六', value: 6},
]

export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '归属店铺',
      key: 'shop',
      slot: 'shop'
    },
    {
      name: '归属经销商',
      key: 'dealer',
      slot: 'dealer'
    },
    {
      name: '分销商VAD',
      key: 'vad',
    },
    {
      name: '渠道RTM',
      key: 'rtm',
    },
    {
      name: '作息日',
      key: 'day',
      type: 'select',
      options: REST_DAY_OPTIONS
    },
  ],
  columns: [
    {
      name: '门店名称',
      key: 'name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              {row.name}
            </div>
            <div class="goods-code">
              <div>
                Apple ID：{row.shop_code}
              </div>
            </div>
          </div>
        )
      }
    },
    {
      name: '所属经销商',
      key: 'dealer_name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              {row.dealer_name}
            </div>
            <div class="goods-code">
              <div>
                HQID：{row.dealer_id}
              </div>
            </div>
          </div>
        )
      }
    },
    {
      name: '组织信息',
      key: 'item_name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              分销商VAD：{row.item_name}
            </div>
            <div>
              渠道RTM：{row.item_bn}
            </div>
          </div>
        )
      }
    },
    {
      name: '维修类型',
      key: 'type',
      width: 100,
      formatter: (v) => ITEM_STATUS_ENUM[v]
    },
    {
      name: '作息设置',
      key: 'type',
      minWidth: 200
    },
  ],
  actions: [
    {
      name: '导出作息',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openExportDialog()
        }
      }
    },
    {
      name: '导入作息',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openImportAddDialog()
        }
      }
    },
    {
      name: '编辑作息',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          // vm.router.push(``)
        }
      }
    }
  ]
})
