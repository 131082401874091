<template>
  <div class="question-container" v-loading="loading" element-loading-text="系统正在匹配店铺，请稍等">
    <el-form ref="form" :model="form" label-width="110px">
      <el-card shadow="never" class="step-card">
        <div v-for="(item, index) in step_list" :key="index" class="step-container">
          <div :class="step_active >= index + 1 ? 'step-no active border-active' : 'step-no'">{{ step_active <= index +
            1 ? index + 1 : '√' }}</div>
              <div class="step-content">
                <div :class="step_active >= index + 1 ? 'title active' : 'title'">{{ item.title }}</div>
                <div>{{ item.desc }}</div>
              </div>
              <div class="arrow-right"></div>
          </div>
      </el-card>

      <el-card class="box-card" style="max-width: 100%;height: 78%;" v-if="step_active == 1">
        <div slot="header" class="clearfix">
          <el-form-item label="选择商品">
            <el-radio-group v-model="select_type">
              <el-radio label="1">手动选择</el-radio>
              <el-radio label="2">模板导入</el-radio>
            </el-radio-group>
            <span class="button-container"></span>
            <el-button v-if="select_type == '1'" type="primary" size="small"
              @click="handleGoodsDialogShow">选择商品</el-button>
            <el-upload v-if="select_type == '2'" class="upload-demo" action=" " :show-file-list="false"
              :auto-upLoad="true" :http-request='uploadHandleChange'>
              <el-button size="small" type="primary">上传商品</el-button>
            </el-upload>
            <el-button v-if="select_type == '2'" size="small" @click="uploadHandleTemplate()"
              type="primary">下载模版</el-button>
            </span>
          </el-form-item>
        </div>
        <div>
          <SpFinder ref="finder" :data="relItems" :setting="setting" no-selection :show-pager="false" :other-config="{
            'max-height': '530',
            'max-width': '1000',
          }"></SpFinder>
        </div>
        <div class="button-nextStep">
          <el-button type="primary" plain @click="nextStep">完成，进入下一步</el-button>
        </div>
      </el-card>

      <el-card class="box-card" style="max-width: 100%;height: 75%;" v-if="step_active == 2">
        <div slot="header" class="clearfix">
          <el-button type="primary" @click="checkVisible">一键导出</el-button>
        </div>
        <el-table :data="resultList" style="width: 100%" max-height="550">
          <el-table-column prop="item_name" label="商品名称">
          </el-table-column>
          <el-table-column prop="item_spec" label="规格名称">
          </el-table-column>
          <el-table-column prop="item_bn" label="商品编码">
          </el-table-column>
          <el-table-column prop="platform_price" label="平台销售参考价">
            <template slot-scope="scope">
              {{ scope.row.platform_price / 100 }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="itemBn" label="平台活动参考价">
            <template slot-scope="scope">
             {{ scope.row.platform_sale_price / 100 }}
            </template>
          </el-table-column> -->
          <el-table-column prop="itemBn" label="销售价复核">
            <template slot="header" slot-scope="scope">
              <div>
                <div>销售价复核</div>
                <div class="check-result">不一致/一致</div>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="check-result-not">{{ scope.row.price_not_same_num }}</span> / {{
                scope.row.price_same_num }}
            </template>
          </el-table-column>
          <el-table-column prop="itemBn" label="活动价复核（门店）">
            <template slot="header" slot-scope="scope">
              <div>
                <div>活动价复核（门店）</div>
                <div class="check-result">不一致/一致</div>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="check-result-not">{{ scope.row.sale_price_not_same_num }}</span> / {{
                scope.row.sale_price_same_num }}
            </template>
          </el-table-column>
          <el-table-column prop="itemBn" label="活动价复核（云仓）">
            <template slot="header" slot-scope="scope">
              <div>
                <div>活动价复核（云仓）</div>
                <div class="check-result">不一致/一致</div>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="check-result-not">{{ scope.row.sale_price_warehouse_not_same_num }}</span> / {{
                scope.row.sale_price_warehouse_same_num }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click.native.prevent="exportRow(scope.row)" type="text" size="small">
                导出
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="button-nextStep">
          <el-button type="primary" plain @click="prevStep">上一步</el-button>
        </div>
      </el-card>
    </el-form>

    <el-dialog title="导出" :visible.sync="dialogVisible" width="30%" :append-to-body="true" :before-close="checkVisible">
      <div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="商品编码" v-if="isItemBn">
            <el-input v-model="form.itemBn" placeholder="多个用 I 隔开，不填默认全部"></el-input>
          </el-form-item>
          <el-form-item label="复核结果">
            <el-checkbox-group v-model="form.checkList">
              <el-checkbox label="price_not_same">销售价不一致</el-checkbox>
              <el-checkbox label="sale_price_not_same">活动价不一致</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportDetail()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="上传失败" :visible.sync="dialogVisibleFail" width="30%" :append-to-body="true" :before-close="closeDialogVisibleFail">
      <SpFinder ref="finder" :data="errorList" :setting="errorSetting" no-selection :show-pager="false" :other-config="{
        'max-height': '530',
        'max-width': '1000',
      }"></SpFinder>
    </el-dialog>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImportDialog from '@/components/importDialog'
import { createSetting } from '@shopex/finder'
import { distributorItemsPriceCheck, getDistributorItemsPriceCheckResult, getDistributorItemsPriceCheckImportItems, getDistributorItemsPriceCheckExportDetail } from '@/api/goods'
import { exportUploadTemplate } from "@/api/common";

export default {
  components: {
    ImportDialog
  },
  data() {
    return {
      setting: createSetting({
        columns: [
          { name: '商品名称', key: 'item_name' },
          { name: '规格名称', key: 'item_spec_desc' },
          { name: '商品编码', key: 'itemBn' },
        ],
        actions: [
          {
            name: '删除',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                let index = this.relItems.findIndex(item => item.item_bn == row[0].item_bn)
                this.relItems.splice(index, 1)
              }
            }
          },
        ]
      }),
      errorSetting:createSetting({
        columns: [
          { name: '商品编码', key: 'item_bn' },
          { name: '错误信息', key: 'msg' },
        ],
      }),
      step_list: [
        { title: '第1步', desc: '选择商品' },
        { title: '第2步', desc: '查看结果' },
      ],
      step_active: 1,
      select_type: '1',
      relItems: [],
      dialogVisible: false,
      form: {
        itemBn: '',
        checkList: []
      },
      resultList: [],
      loading: false,
      isItemBn: true,
      dialogVisibleFail: false,
      errorList: []
    }
  },
  computed: {
    ...mapGetters(["wheight"])
  },
  methods: {
    async handleGoodsDialogShow() {
      const { data } = await this.$picker.goodsitem({
        data: this.relItems,
        paramsFieldExclude: ['distributor_id'],
        queryParams: {
          is_sku: true,
        },
        rowKey: 'item_id',
        multiple: true
      })
      this.relItems = data
    },
    nextStep() {
      if (this.relItems.length == 0) {
        this.$message.warning('请选择商品')
        return
      }
      let params = {
        item_ids: this.relItems.map(item => item.item_id).join(','),
      }
      this.loading = true
      distributorItemsPriceCheck(params).then(res => {
        this.distributorItemsPriceCheckResult()
      }).catch(() => {
        this.loading = false
      })
    },
    distributorItemsPriceCheckResult() {
      getDistributorItemsPriceCheckResult().then(res => {
        if (res.data.data.length > 0) {
          this.resultList = res.data.data
          this.step_active = 2
        }
        this.loading = false
      })
    },
    prevStep() {
      this.step_active = 1
    },
    exportRow(row) {
      this.form.itemBn = row.item_bn
      this.isItemBn = false
      this.checkVisible()
    },
    checkVisible() {
      this.dialogVisible = !this.dialogVisible
      if (!this.dialogVisible) {
        this.isItemBn = true
        this.form = {
          itemBn: '',
          checkList: []
        }
      }
    },
    // 上传商品
    uploadHandleChange(request) {
      let params = {
        file: request.file,
        isUploadFile: true
      }
      getDistributorItemsPriceCheckImportItems(params).then(res => {
        console.log('111111111111111111111111111111------------------------', res)
        this.relItems = res.data.success
        if (res.data.error.length > 0) {
          this.errorList = res.data.error
          this.dialogVisibleFail = true
        }
      })
    },
    uploadHandleTemplate() {
      let params = { file_type: 'upload_check_price_items', file_name: '导出模板', };
      exportUploadTemplate(params).then((response) => {
        if (response.data.data.file) {
          var a = document.createElement("a");
          a.href = response.data.data.file;
          a.download = response.data.data.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          this.$message({
            type: "error",
            message: "没有相关数据可导出",
          });
        }
      });
    },
    exportDetail() {
      let params = {
        item_bn: this.form.itemBn,
        price_not_same: this.form.checkList.includes('price_not_same') ? 1 : 0,
        sale_price_not_same: this.form.checkList.includes('sale_price_not_same') ? 1 : 0,
      }
      getDistributorItemsPriceCheckExportDetail(params).then(res => {
        if (res.data.data.success) {
          this.checkVisible()
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
        }
      })
    },
    closeDialogVisibleFail() {
      this.dialogVisibleFail = false
    }
  },
  mounted() {
    this.distributorItemsPriceCheckResult()
  }
}
</script>

<style lang="scss">
.clearfix {
  .el-form-item {
    margin-bottom: 0;
  }
}

.button-container {
  margin-left: 20px
}

.upload-demo {
  display: inline-block;

  .el-upload {
    height: 32px;
  }
}

.button-nextStep {
  margin-top: 10px;
}

.check-result {
  font-size: 12px;
  color: #409EFF;
}

.check-result-not {
  color: #f00;
}
</style>
