/* eslint-disable */
<div style="max-width:403px;max-height:300px" id="container123"></div>
<template>
  <section class="section section-white content-padded" onload="init()">
    <el-form ref="form" :model="form" label-width="190px">
      <el-form-item label="经销商名称">
        <div>
          <el-col :span="8">
            <el-input v-model="form.name" placeholder="请输入经销商名称"></el-input>
          </el-col>
        </div>
      </el-form-item>
      <el-form-item label="经销商别名">
        <div>
          <el-col :span="8">
            <el-input v-model="form.alias_name" placeholder="请输入经销商别名"></el-input>
          </el-col>
        </div>
      </el-form-item>
      <el-form-item label="经销商HQID">
        <div>
          <el-col :span="8">
            <el-input v-model="form.dealer_hqid" placeholder="请输入经销商HQID"></el-input>
          </el-col>
        </div>
      </el-form-item>
      <el-form-item label="企业微信HQID">
        <div>
          <el-col :span="8">
            <el-input v-model="form.external_dealer_hqid" placeholder="请输入企业微信HQID"></el-input>
          </el-col>
        </div>
      </el-form-item>
      <el-form-item label="分销商HQID">
        <div>
          <el-col :span="8">
            <el-input v-model="form.distributor_hqid" placeholder="请输入分销商HQID"></el-input>
          </el-col>
        </div>
      </el-form-item>
      <el-form-item label="分销商名称(VAD)">
        <div>
          <el-col :span="8">
            <el-input v-model="form.distributor_vad" placeholder="分销商名称(VAD)"></el-input>
          </el-col>
        </div>
      </el-form-item>
      <el-form-item label="渠道(RTM)">
        <div>
          <el-col :span="8">
            <el-input v-model="form.channel_rtm" placeholder="渠道(RTM)"></el-input>
          </el-col>
        </div>
      </el-form-item>
      <el-form-item label="线下商户号">
        <div>
          <el-col :span="8">
            <el-input v-model="form.merch_no" placeholder="汇付天下系统商户号"></el-input>
          </el-col>
        </div>
      </el-form-item>
      <el-form-item label="线上商户号">
        <div>
          <el-col :span="8">
            <el-input v-model="form.merch_no_ol" placeholder="汇付天下系统商户号"></el-input>
          </el-col>
        </div>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-radio-group v-model="form.is_valid" size="small">
          <el-radio label="true" border >启用</el-radio>
          <el-radio label="false" border>禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="使用oms【订单、库存】">
        <el-radio-group v-model="form.send_oms" size="small">
          <el-radio label="true" border>启用</el-radio>
          <el-radio label="false" border>禁用</el-radio>
        </el-radio-group>
      </el-form-item>
<!--      <el-form-item label="地理位置">-->
<!--        <div class="frm-tips">输入经销商的地理位置</div>-->
<!--        <el-cascader-->
<!--          v-model="form.regions_id"-->
<!--          :options="regions"-->
<!--          @change="handleRegionChange"-->
<!--          style="width:200px"-->
<!--        ></el-cascader>-->
<!--        <el-input v-model="form.address" id="keyword" placeholder="请输入地址" style="width:500px"></el-input>-->
<!--        <el-button type="primary" @click="searchKeyword()">搜索定位</el-button>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="地图定位">-->
<!--        <div id="qqmap_container"></div>-->
<!--      </el-form-item>-->
<!--      <el-form-item inline="true" class="demo-form-inline" label="经销商经纬度">-->
<!--        <el-col :span="3">-->
<!--          <el-input v-model="form.lng" readonly placeholder="经度"></el-input>-->
<!--        </el-col>-->
<!--        <el-col :span="1" class="content-center">-</el-col>-->
<!--        <el-col :span="3">-->
<!--          <el-input v-model="form.lat" readonly placeholder="纬度"></el-input>-->
<!--        </el-col>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="联系人">-->
<!--        <el-input v-model="form.contact" placeholder="联系人" style="width: 55%;"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="联系方式">-->
<!--        <el-input v-model="form.mobile" placeholder="请输入手机号" style="width: 55%;"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-row>-->
<!--        <el-col :span="8">-->
<!--          <el-form-item label="经销商LOGO">-->
<!--            <div @click="handleImgPicker('logo')" class="upload-box">-->
<!--              <img v-if="form.logo" :src="form.logo" class="avatar" />-->
<!--              <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>-->
<!--            </div>-->
<!--          <div class="frm-tips">只能上传jpg/png文件，且不超过2M </div>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      </el-row>-->
      <div class="section-footer with-border content-center">
        <el-button size="large" @click="cancelSubmit">取消</el-button>
        <el-button
          type="primary"
          @click="submitItemsActionConfirm"
          :loading="submitLoading"
        >{{submitLoading ? '提交中' : '保存'}}</el-button>
      </div>
    </el-form>
<!--    <div id="qqmap_infowin" v-show="qqmap_infowin_flag">
      <el-col>
        <el-button type="primary" @click="imp_poi(poi_info)">导入该位置信息</el-button>
      </el-col>
      <el-col>{{poi_info.name}}</el-col>
      <div class="frm-tips">{{poi_info.address}}</div>
    </div>-->
<!--    <imgPicker
      :dialog-visible="imgDialog"
      :sc-status="isGetImage"
      @chooseImg="pickImg"
      @closeImgDialog="closeImgDialog"
    ></imgPicker>-->
<!--    <DistributorSelect :store-visible="DistributorVisible" :is-valid="isValid" :rel-data-ids="relDistributors" :get-status="DistributorStatus" @chooseStore="DistributorChooseAction" @closeStoreDialog="closeDialogAction"></DistributorSelect>-->

  </section>
</template>
<script>
import imgPicker from '@/components/imageselect'
import district from '../../../../common/district.json'
import DistributorSelect from '@/components/function/distributorSelect'
import {   saveDealer, getDealerInfo } from '@/api/marketing'
import { getRegionauth } from '@/api/regionauth'
import { getSetting} from '@/api/fenzhang'

// 取选中地区的值
function getCascaderObj(val, opt) {
  return val.map(function (value, index, array) {
    for (var itm of opt) {
      if (itm.value === value) {
        opt = itm.children
        return itm
      }
    }
    return null
  })
}

export default {
  components: {
    imgPicker,
    DistributorSelect
  },
  data() {
    return {
      imgDialog: false,
      isValid: true,
      relDistributors: [],
      DistributorStatus: false,
      isGetImage: false,
      startTime: '08:00',
      endTime: '21:00',
      submitLoading: false,
      pickerImgType: 'logo',
      DistributorVisible: false,
      form: {
        is_valid: 'true',
        send_oms: 'false',
        alias_name: '',
        address: '',
        shop_code: '',
        distributor_ids: [],
        dealer_hqid: '',
        external_dealer_hqid: '',
        distributor_hqid: '',
        distributor_vad: '',
        channel_rtm: '',
        merch_no: '',
        merch_no_ol: '',
      },
      dealer_id: 0,
      distributor_type: '',
      searchRegion: '',
      qqmap_infowin_flag: false,
      regions: district,
      searchService: [],
      markers: [],
      poi_info: [],
      rules: {
        shop_code: [{ pattern: /^[A-Za-z0-9]+$/, message: '请输入正确格式的店铺号' }],
      },
      regionauth_list: [],
      isOpen:false
    }
  },
  methods: {
    DistributoreHandleClose(index) {
      this.DistributorStatus = false
      this.DistributorVisible = false
      this.relDistributors.splice(index, 1);
    },
    // DistributorChooseAction (data) {
    //   this.DistributorVisible = false
    //   this.DistributorStatus = false
    //   if (data === null || data.length <= 0) return
    //   this.relDistributors = data
    // },
    closeDialogAction () {
      this.DistributorStatus = false
      this.DistributorVisible = false
    },
    addDistributoreAction() {
      this.DistributorVisible = true
      this.DistributorStatus = true
    },
    pickImg(data) {
      if (this.pickerImgType == 'logo') {
        this.form.logo = data.url
      } else {
        this.form.banner = data.url
      }
      console.log(data)
      this.imgDialog = false
    },
    handleImgPicker(pickerImgType) {
      this.pickerImgType = pickerImgType
      this.imgDialog = true
      this.isGetImage = true
    },
    closeImgDialog() {
      this.imgDialog = false
      this.isGetImage = false
    },
    cancelSubmit() {
      this.$router.go(-1)
    },
    submitItemsActionConfirm() {
      this.submitLoading = true
      if (this.form.shop_code) {
        this.form.shop_code = this.form.shop_code
      }
      this.form.distributor_ids = []
      // if (this.relDistributors.length > 0) {
      //   this.relDistributors.forEach( distributor => {
      //     this.form.distributor_ids.push({distributor_id: distributor.distributor_id});
      //   })
      // }
      this.form.hour = this.startTime + '-' + this.endTime
      saveDealer(this.form)
        .then((res) => {
          this.submitLoading = false
          this.$message({ type: 'success', message: '保存店铺成功' })
          if (this.$store.getters.login_type != 'distributor') {
            this.$router.go(-1)
          }
        })
        .catch((error) => {
          this.submitLoading = false
        })
    },
    handleRegionChange: function (value) {
      var vals = getCascaderObj(value, this.regions)
      this.form.regions_id = []
      this.form.regions = []
      for (var i = 0; i < vals.length; i++) {
        this.form.regions_id[i] = vals[i].value
        this.form.regions[i] = vals[i].label
      }
      if (this.form.regions[0] == '北京市' || this.form.regions[0] == '天津市' || this.form.regions[0] == '上海市' || this.form.regions[0] == '重庆市') {
        this.searchRegion = this.form.regions[0] + this.form.regions[2]
      } else {
        this.searchRegion = this.form.regions[0] + this.form.regions[1] + this.form.regions[2]
      }
    },
    qqmapinit: function (lat, lng) {
      // var center = new qq.maps.LatLng(39.916527, 116.397128);
      var center = new qq.maps.LatLng(lat, lng)
      var map = new qq.maps.Map(document.getElementById('qqmap_container'), {
        center: center,
        zoom: 13,
      })
      //创建marker
      var marker = new qq.maps.Marker({
        position: center,
        map: map,
      })
      var that = this
      //设置Poi检索服务，用于本地检索、周边检索
      this.searchService = new qq.maps.SearchService({
        panel: document.getElementById('qqmap_rslist'),
        //检索成功的回调函数
        complete: function (results) {
          //设置回调函数参数
          var pois = results.detail.pois
          var infoWin = new qq.maps.InfoWindow({
            map: map,
          })
          var latlngBounds = new qq.maps.LatLngBounds()
          for (var i = 0, l = pois.length; i < l; i++) {
            var poi = pois[i]
            //扩展边界范围，用来包含搜索到的Poi点
            latlngBounds.extend(poi.latLng)
            ;(function (n) {
              var marker = new qq.maps.Marker({
                map: map,
              })
              marker.setPosition(pois[n].latLng)

              marker.setTitle(i + 1)
              that.markers.push(marker)
              qq.maps.event.addListener(marker, 'click', function () {
                infoWin.open()
                that.qqmap_infowin_flag = true
                that.poi_info = pois[n] //将选点位置信息存入poi_info
                infoWin.setContent(document.getElementById('qqmap_infowin'))
                infoWin.setPosition(pois[n].latLng)
              })
            })(i)
          }
          map.fitBounds(latlngBounds)
        },
        //若服务请求失败，则运行以下函数
        error: function () {
          this.$message.error('未查询到数据')
        },
      })
    },
    clearOverlays: function (overlays) {
      //清除地图上的marker
      var overlay
      while ((overlay = overlays.pop())) {
        overlay.setMap(null)
      }
    },
    searchKeyword: function () {
      //设置搜索的范围和关键字等属性
      if (!this.searchRegion) {
        this.$message({
          message: '请选择地区',
          type: 'warning',
        })
        return ''
      }
      if (!this.form.address) {
        this.$message({
          message: '请输入具体位置',
          type: 'warning',
        })
        return ''
      }
      var region = this.searchRegion
      var keyword = this.form.address
      var pageIndex = 0
      var pageCapacity = 5
      this.clearOverlays(this.markers)
      //根据输入的城市设置搜索范围
      this.searchService.setLocation(region)
      //设置搜索页码
      this.searchService.setPageIndex(pageIndex)
      //设置每页的结果数
      this.searchService.setPageCapacity(pageCapacity)
      //根据输入的关键字在搜索范围内检索
      this.searchService.search(keyword)
    },
    imp_poi: function (poi_info) {
      // this.form.name = poi_info.name
      this.form.lng = poi_info.latLng.lng
      this.form.lat = poi_info.latLng.lat
      this.form.address = poi_info.address.substr(this.searchRegion.length)
    },
    removeByValue: function (arr, val) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == val) {
          arr.splice(i, 1)
          break
        }
      }
      return arr
    },
    getRegionauthList() {
      this.loading = true
      getRegionauth().then((res) => {
        this.regionauth_list = res.data.data.list
        this.regionauth_total = res.data.data.total_count
        this.loading = false
      })
    },
  },
  mounted() {
    this.getRegionauthList()
    this.dealer_id = this.$route.query.dealer_id
    if (this.dealer_id || this.$store.getters.login_type == 'distributor') {
      getDealerInfo({ dealer_id: this.dealer_id }).then((res) => {
        this.relDistributors = res.data.data.distributor
        this.form = res.data.data
        if (res.data.data.hour) {
          // 处理营业时间的格式
          var open_time = res.data.data.hour.split('-')
          this.startTime = open_time[0]
          this.endTime = open_time[1]
        }

        this.searchRegion = this.form.area
        if (!this.form.lat) {
          this.qqmapinit(39.916527, 116.397128)
        } else {
          this.qqmapinit(this.form.lat, this.form.lng)
        }
      })
    } else {
      // 添加门店时初始化地图
      this.qqmapinit(39.916527, 116.397128)
    }
    this.distributor_type = this.$route.query.distributor_type
    if (this.distributor_type) {
      this.form.distributor_self = 1
    }
    getSetting().then(res=>{
      let data = res.data.data
      this.isOpen = data.is_open == 'true'
    })
  },
}
</script>

<style lang="scss">
.bm-view {
  width: 100%;
  height: 300px;
}
#qqmap_rslist {
  border-right: 1px solid #e7e7eb;
}
#qqmap_container {
  float: left;
  width: 800px;
  height: 400px;
}
</style>
