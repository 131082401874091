import { createSetting } from '@shopex/finder'
import { batchDelete, deleteItem, closeOrder, retryCancelOrder } from '../api'
import { formatTimeStampToStr } from '@/utils/index'
import { MessageBox } from 'element-ui'
import { useDialog } from '@shopex/finder'

export const StatusOption = [
  {
    label: '全部',
    value: '0'
  },
  {
    label: '有效',
    value: '1'
  },
  {
    label: '无效',
    value: '2'
  }
]
const commonActions = [
  { label: '取消', key: 'close', size: 'default' },
  { label: '确定', key: 'save', type: 'primary', size: 'default' }
]
export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '姓名',
      key: 'name',
    },
    {
      name: '手机号',
      key: 'mobile',
    },
    {
      name: '添加时间',
      key: 'createTime',
      type: 'date-time-picker',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      startPlaceholder: '开始时间',
      endPlaceholder: '结束时间'
    },
  ],
  columns: [
    { name: '姓名', key: 'name', minWidth: 150 },
    { name: '手机号', key: 'mobile', minWidth: 150 },
    {
      name: '有效期',
      key: 'time',
      minWidth: 200,
      render(_, {row}) {
        if(!row.start_time) return '-'
        return `${row.start_time}~${row.end_time}`
      }
    },
    { name: '添加时间', key: 'created', minWidth: 150 },
  ],
  actions: [
    {
      name: '添加白名单',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          useDialog(null, {
            title: `添加白名单`,
            width: '650px',
            component: () => import('../operation.vue'),
            actions: commonActions
          }).then((args) => {
            if(args) {
              vm.refresh()
            }
          })
        }
      }
    },
    {
      name: '批量导入',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openImportAddDialog()
        }
      }
    },
    {
      name: '批量导出',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openExportDialog()
        }
      }
    },
    {
      name: '批量删除',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async (rows) => {
          if(!rows.length) {
            vm.$message.error('请选择要删除的白名单')
            return
          }
          MessageBox.confirm('请确认是否批量删除？', '提示').then(async () => {
            const ids = rows.map(v => v.id).join(',')
            await batchDelete(ids)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    },
    {
      name: '编辑',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (rows) => {
          useDialog(null, {
            title: `编辑白名单`,
            width: '650px',
            orgData: rows[0],
            component: () => import('../operation.vue'),
            actions: commonActions
          }).then((args) => {
            if(args) {
              vm.refresh()
            }
          })
        }
      }
    },
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (rows) => {
          MessageBox.confirm('请确认是否删除？', '提示').then(async () => {
            const id = rows[0].id
            await batchDelete(id)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    }
  ]
})
