<template>
  <div>
    <SpFinder
      ref="finder"
      row-key="goods_id"
      :split-count="10"
      :url="table_url"
      :setting="setting"
      fixed-row-action
      reserve-selection
      row-actions-fixed-align="left"
    />
  </div>
</template>
<script>
import { tableSetting } from './config'
import { List } from './api'

export default {
  components: {},
  data () {
    return {
      table_url: List,
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {
  },
  methods: {
    refresh() {
      this.$refs.finder.refresh()
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}
</style>
