import { createSetting } from '@shopex/finder'

const ITEM_STATUS_ENUM = {
  1: 'ASP',
  2: '非ASP'
}

// 是否
const REST_DAY_OPTIONS = [
  {label: '周日', value: 0},
  {label: '周一', value: 1},
  {label: '周二', value: 2},
  {label: '周三', value: 3},
  {label: '周四', value: 4},
  {label: '周五', value: 5},
  {label: '周六', value: 6},
]

export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '添加时间',
      key: 'addTime',
      type: 'date-time-picker',
      valueFormat: 'timestamp',
      startPlaceholder: '开始时间',
      endPlaceholder: '结束时间'
    },
    {
      name: '操作账号',
      key: 'vad',
    },
    {
      name: '归属店铺',
      key: 'shop',
      slot: 'shop'
    },
    {
      name: '归属经销商',
      key: 'dealer',
      slot: 'dealer'
    },
    {
      name: '作息日',
      key: 'day',
      type: 'select',
      options: REST_DAY_OPTIONS
    },
    {
      name: '操作类型',
      key: 'day',
      type: 'select',
      options: REST_DAY_OPTIONS
    },
  ],
  columns: [
    {
      name: '变更门店',
      key: 'item_name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              {row.item_name}
            </div>
            <div class="goods-code">
              <div>
                Apple ID：{row.item_bn}
              </div>
            </div>
          </div>
        )
      }
    },
    {
      name: '所属经销商',
      key: 'item_name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              {row.item_name}
            </div>
            <div class="goods-code">
              <div>
                HQID：{row.item_bn}
              </div>
            </div>
          </div>
        )
      }
    },
    {
      name: '变更作息日',
      key: 'type',
      width: 120,
      formatter: (v) => ITEM_STATUS_ENUM[v]
    },
    {
      name: '变更类型',
      key: 'type',
      minWidth: 120,
      formatter: (v) => ITEM_STATUS_ENUM[v]
    },
    {
      name: '操作时间',
      key: 'type',
      minWidth: 150,
    },
    {
      name: '操作方式',
      key: 'type',
      minWidth: 120,
      formatter: (v) => ITEM_STATUS_ENUM[v]
    },
    {
      name: '操作账号',
      key: 'type',
      minWidth: 150,
    },
    {
      name: '账号类型',
      key: 'type',
      minWidth: 120,
      formatter: (v) => ITEM_STATUS_ENUM[v]
    },
    {
      name: '变更明细',
      key: 'change',
      minWidth: 120,
      render: (h, {row}) => {
        const changeDetails = [
          { label: '作息运营状态', before: '暂停', after: '运营' },
          { label: '场次名称', before: '上午场', after: '上午场11点' },
          { label: '是否限额', before: '是', after: '是' },
          { label: '场次名额', before: '10', after: '15' },
          { label: '可预约截止时间', before: '10:20', after: '10:45' },
          { label: '最晚可履约时间', before: '11:45', after: '12:30' },
          { label: '不可维修商品', before: 'iphone', after: 'iphone' }
        ]
        return (
          <el-popover
            placement="top-start"
            width="400"
            trigger="click">
            <div class="change-detail-container">
              <div class="change-detail-header">
                <span class="header-cell"></span>
                <span class="header-cell">变更前内容</span>
                <span class="header-cell">变更后内容</span>
              </div>
              {changeDetails.map(item => (
                <div class="change-detail-row">
                  <span class="label">{item.label}:</span>
                  <span class="content">{item.before}</span>
                  <span class="content">{item.after}</span>
                </div>
              ))}
            </div>
            <el-button slot="reference" type="text">查看</el-button>
          </el-popover>
        )
      }
    },
  ],
  actions: [
    {
      name: '导出报表',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openExportDialog()
        }
      }
    }
  ]
})
