<style scoped lang="scss">
  .goods-title {
    padding-bottom: 5px;
  }
  .goods-code {
    color: #888;
    font-size: 13px;
    i {
      cursor: pointer;
    }
  }
</style>
<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1">
      <el-row class="filter-header" :gutter="20">
        <el-col>
          <el-input class="input-m" placeholder="商品名称" v-model="params.keywords">
            <el-button slot="append" icon="el-icon-search" @click="goodsSearch"></el-button>
          </el-input>
          <el-input class="input-m" placeholder="商品货号" v-model="params.item_bn">
            <el-button slot="append" icon="el-icon-search" @click="goodsSearch"></el-button>
          </el-input>
          <!-- <el-select v-model="templates_id" @change="handleChangeTemplates" placeholder="运费模板" clearable>
            <el-option v-for="item in templatesList" :key="item.template_id" :label="item.name" :value="item.template_id"></el-option>
          </el-select> -->
          <!-- <el-cascader
            placeholder="商品产地"
            :options="regions"
            v-model='select_regions_value'
            clearable
            @change="searchAction">
          </el-cascader> -->
          <el-select v-model="params.approve_status" clearable placeholder="商品状态" @change="goodsSearch">
            <el-option v-if="login_type != 'distributor'" v-for="item in statusOption" :key="item.value"
                       :label="item.title" size="mini" :value="item.value"></el-option>
            <el-option v-if="login_type == 'distributor'" v-for="item in shopStatusOption" :key="item.value"
                       :label="item.title" size="mini" :value="item.value"></el-option>
          </el-select>
          <el-select v-if="login_type != 'admin'"
            placeholder="选择品牌"
            remote
            filterable
            :remote-method="getGoodsBranchList"
            clearable
            v-model='select_branch_value'
            @change="searchAction">
            <el-option
              v-for="item in goodsBranchList"
              :key="item.attribute_id"
              :label="item.attribute_name"
              :value="item.attribute_id"
            ></el-option>
          </el-select>
          <el-cascader
            placeholder="商品分类" v-if="login_type != 'admin'"
            :options="categoryList"
            :props="{value:'category_id', checkStrictly: true}"
            v-model='select_category_value'
            clearable
            @change="searchAction">
          </el-cascader>
          <el-cascader
            placeholder="商品标签" v-if="login_type != 'admin'"
            :options="tag.list"
            :props="{value:'tag_id', label: 'tag_name'}"
            v-model='select_tags_value'
            clearable
            @change="searchAction">
          </el-cascader>
          <el-select v-model="params.has_recommend" clearable placeholder="是否搭售" @change="goodsSearch">
            <el-option label="是" size="mini" value="1"></el-option>
            <el-option label="否" size="mini" value="2"></el-option>
          </el-select>
          <!-- TODO 商品列表 -->
          <el-select v-model="params.type_warehouse" clearable placeholder="市场分类" @change="goodsSearch">
            <el-option label="配件" size="mini" value="2"></el-option>
            <el-option label="非配件" size="mini" value="1"></el-option>
          </el-select>
          <el-select v-model="params.auto_receiving" clearable placeholder="支持自动接单" @change="goodsSearch">
            <el-option label="是" size="mini" value="true"></el-option>
            <el-option label="否" size="mini" value="false"></el-option>
          </el-select>
          <el-button type="primary" @click="resetParams">重置</el-button>

          <!-- <el-select v-model="params.special_type" clearable placeholder="商品类型" @change="searchAction">
            <el-option
              placeholder="商品类型"
              v-for="item in special_type_list"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select> -->
        </el-col>
        <!--
        <el-col :md="3" :lg="3">
          <el-select size="small" v-model="params.is_gift" placeholder="是否赠品" @change="searchAction" style="width: 110px">
            <el-option label="全部" value="all"></el-option>
            <el-option label="赠品" :value="true"></el-option>
            <el-option label="商品" :value="false"></el-option>
          </el-select>
        </el-col>
        -->
      </el-row>
      <el-row :gutter="20">
        <el-col>
          <el-button-group>
            <span v-if="login_type == 'admin-del' || login_type == 'staff-del' || login_type == 'disti'">
              <el-button size="small" type="primary" @click="addCategory">更改商品分类</el-button>
              <!-- <el-button size="small" type="primary" @click="addTemplates">更改运费模板</el-button> -->
              <el-button size="small" type="primary" @click="addItemTag">打标签</el-button>
              <!-- <el-button size="small" type="primary" @click="batchGifts('true')">批量设置为赠品</el-button> -->
              <!-- <el-button size="small" type="primary" @click="batchGifts('false')">批量设置为非赠品</el-button> -->
              <el-button size="small" type="primary" @click="exportItemsData()">导出商品信息</el-button>
              <el-button size="small" type="primary" @click="exportRecommendItemsData()">导出搭售商品信息</el-button>
              <!-- <el-button size="small" type="primary" @click="syncItems">同步商品数据</el-button> -->
            </span>
            <!-- <span v-if="login_type == 'dealer' || login_type == 'admin'|| login_type == 'distributor'"><el-button size="small" type="primary" @click="batchItemsStore">统一库存</el-button></span> -->
            <span v-if="login_type == 'admin-del' || login_type == 'staff-del' || login_type == 'disti'"><el-button size="small" type="primary" @click="batchItemsStatus('onsale')">批量上架</el-button></span>
            <span v-if="login_type == 'admin-del' || login_type == 'staff-del' || login_type == 'disti'"><el-button size="small" type="primary" @click="batchItemsStatus('instock')">批量下架</el-button></span>
            <span v-if="login_type == 'disti'"><el-button size="small" type="primary" @click="batchItemsAuthReceiving('true')">批量设置自动接单</el-button></span>
            <span v-if="login_type == 'disti'"><el-button size="small" type="primary" @click="batchItemsAuthReceiving('false')">批量关闭自动接单</el-button></span>
          </el-button-group>
          <span>
            <el-button v-if="login_type == 'admin-del' || login_type == 'staff-del' || login_type == 'disti'" size="small" type="primary" icon="el-icon-circle-plus" plain @click="addItems">添加商品</el-button>

            <el-button v-if="login_type == 'admin-del' || login_type == 'staff-del' || login_type == 'disti' || login_type == 'dealer'" size="small" type="primary" icon="el-icon-s-shop" plain @click="handleitemsSyncToShop">同步至店铺</el-button>
            <el-button v-if="login_type == 'admin-del' || login_type == 'staff-del' || login_type == 'disti' || login_type == 'dealer'" size="small" type="primary" icon="el-icon-s-shop" plain @click="showitemsSyncToShop">查看同步记录</el-button>
          </span>
        </el-col>
      </el-row>
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in tabList" :key="index" :label="item.name" :name="item.activeName">
          <div v-if="activeName == 'second'" style="margin-bottom: 15px; width:280px;">
            <el-input v-model="warning_store" value="warning_store">
              <template slot="prepend">预警数量</template>
              <el-button slot="append" @click="setWarningStore">保存</el-button>
            </el-input>
          </div>
          <el-table :data="ItemsList" @selection-change="handleSelectionChange" v-loading="loading" row-key="goods_id">
            <el-table-column type="selection" align="center" label="全选" :reserve-selection="true"></el-table-column>
            <el-table-column label="操作" width="110">
              <template slot-scope="scope">
                <span v-if="login_type == 'admin' || login_type == 'staff' || login_type == 'disti'">
                  <el-button type="text" class="btn-gap" @click="handlePreview(scope.row)">预览</el-button>
                  <!-- <el-button type="text" @click="editItemsAction(scope.$index, scope.row, false)" class="btn-gap">编辑</el-button> -->
                </span>

                <span v-if="login_type == 'admin-del' || login_type == 'staff-del' || login_type == 'disti'">
                  <el-button type="text" @click="editItemsAction(scope.$index, scope.row, false)" class="btn-gap">编辑</el-button>
                </span>

                <el-popover
                  placement="right"
                  width="450"
                  trigger="hover">
                <div class="operating-icons">
                  <span v-if="login_type == 'admin-del' || login_type == 'staff-del' || login_type == 'disti'">
                    <el-button type="text" @click="deleteItemsAction(scope.$index, scope.row)" class="btn-gap">删除</el-button>
                    <el-button type="text" @click="editItemsAction(scope.$index, scope.row, true)" class="btn-gap">添加相似</el-button>

                    <!--el-button v-if="popularizeSetting.isOpenPopularize == 'true'" type="text" @click="handleRebateConf(scope.row)" class="btn-gap">分销配置</el-button-->
                    <el-button type="text" @click="handleProfitPrice(scope.row)" class="btn-gap">分润配置</el-button>
                    <el-button type="text" @click="tagUpdate(scope.row)" class="btn-gap">标签</el-button>
                  </span>
                  <span v-if="login_type == 'admin-del' || login_type == 'dealer' || login_type == 'staff-del'  || login_type == 'disti'">
                  <el-button type="text" @click="handlePrice(scope.row)" class="btn-gap">设置价格</el-button>
                  </span>

                  <span v-if="login_type == 'admin-del' || login_type == 'dealer' || login_type == 'staff-del'  || login_type == 'disti'">
                    <el-button type="text" @click="updateItemsStore(scope.row)" class="btn-gap" >更改库存</el-button>
                  </span>
                  <el-button class="copy-btn"
                    type="text"
                    v-clipboard:copy="scope.row.link"
                    v-clipboard:success="onCopy">
                    <input class="copy-link" type="text" v-model="scope.row.link"></input>复制链接
                  </el-button>
                  <span v-if="login_type == 'admin-del' || login_type == 'dealer' || login_type == 'staff-del'  || login_type == 'disti'">
                  <el-button type="text" v-if="scope.row.approve_status == 'onsale'" @click="updateItemStatus(scope.row)" class="btn-gap" >下架</el-button>
                  <el-button type="text" v-if="scope.row.approve_status == 'instock'" @click="updateItemStatus(scope.row)" class="btn-gap" >上架</el-button>
                  </span>

                  <el-button type="text" @click="goodsOmsinfo(scope.row)" class="btn-gap" >更新市场分类</el-button>
                  <el-button type="text" @click="handleParamSetting(scope.row)" >参数设置</el-button>

                </div>
                <el-button type="text" slot="reference"><i class="iconfont icon-angle-double-right"></i></el-button>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column type="expand" label="标签">
              <template slot-scope="scope">
                <template v-if="scope.row.tagList.length > 0">
                  <el-tag
                          :hit="false"
                          effect="dark"
                          type="info"
                          v-for="taglist in scope.row.tagList"
                          :key="taglist.index"
                          :color="taglist.tag_color"
                          size="mini"
                          :style="'color:'+ taglist.font_color"
                          style="display: inline-block; margin-right:3px; border: #FFFFFF 1px solid;"
                  >{{taglist.tag_name}}</el-tag>
                </template>
                <template v-else>
                  <span class="muted">暂无标签</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="goods_id" label="商品ID" width="80"></el-table-column>
            <el-table-column prop="itemName" label="商品" width="400">
              <template slot-scope="scope">
                <div class="goods-title">
                  {{scope.row.item_name}}
                  <el-tag size="mini" effect="plain" type="primary" v-show="!scope.row.nospec">多规格</el-tag>
                  <el-tag size="mini" type="success" effect="plain" v-if="scope.row.item_tier ==='acplus'">AC+</el-tag>
                  <el-tag size="mini" type="success" effect="plain" v-if="scope.row.item_tier === 'iup'">iUP</el-tag>
                  <el-popover
                    placement="top"
                    width="230">
                    <el-input class="input-m" size="mini" placeholder="教育优惠活动参数" v-model="scope.row.edu_activity_param"></el-input>
                    <div style="text-align: right; margin: 15px 0 0 0">
<!--                      <el-button size="mini" type="text" @click="scope.row.eduParamsVisible = false">取消</el-button>-->
                      <el-button type="primary" size="mini" @click="handleEduParams(scope.row)">确定</el-button>
                    </div>
                    <el-tag slot="reference" v-show="scope.row.is_edu == 1" effect="plain" size="mini"  type="success" style="margin-left: 0">教育优惠</el-tag>
                  </el-popover>
                </div>
                <div class="goods-code">
                  <div>
                    货号：{{scope.row.itemBn}}
                    <el-tooltip effect="dark" content="复制" placement="top-start">
                      <i v-clipboard:copy="scope.row.itemBn" v-clipboard:success="onCopy" class="el-icon-document-copy"></i>
                    </el-tooltip>
                  </div>
                  <div v-if="scope.row.origin_bn">
                    原始货号：{{scope.row.origin_bn}}
                    <el-tooltip effect="dark" content="复制" placement="top-start">
                      <i v-clipboard:copy="scope.row.itemBn" v-clipboard:success="onCopy" class="el-icon-document-copy"></i>
                    </el-tooltip>
                  </div>
                </div>
                <!-- <el-tag type="danger" size="mini" v-if="scope.row.special_type == 'drug'">处方药</el-tag> -->
              </template>
            </el-table-column>

            <el-table-column label="市场分类" width="120">
              <template slot-scope="scope">
                <el-button v-if="scope.row.type_warehouse == 2" size="mini" type="primary" plain>配件</el-button>
                <el-button v-if="scope.row.type_warehouse == 1" size="mini" type="warning" plain>非配件</el-button>
              </template>
            </el-table-column>

            <el-table-column label="搭售商品" width="90">
              <template slot-scope="scope">
                <el-popover
                  v-if="scope.row.recommend_num>0"
                  placement="right"
                  trigger="click">
                  <el-table :data="scope.row.recommend_items" max-height="500">
                    <el-table-column width="100" property="item_id" label="商品ID"></el-table-column>
                    <el-table-column width="200" property="item_name" label="商品名称"></el-table-column>
                    <el-table-column width="200" property="item_bn" label="商品货号"></el-table-column>
                  </el-table>
                  <el-link type="primary" slot="reference">{{scope.row.recommend_num}}</el-link>
                </el-popover>
                <span v-else>{{scope.row.recommend_num}}</span>
              </template>
            </el-table-column>
            <el-table-column label="排序编号" width="90" v-if="login_type == 'admin-del' || login_type == 'dealer' || login_type == 'staff-del'  || login_type == 'disti'">
              <template slot-scope="scope">
                <el-input v-model="scope.row.sort" @change="editItemsSort(scope.$index, scope.row)" size="mini"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="排序编号" width="90" v-if="login_type == 'admin' ||  login_type == 'staff'">
              <template slot-scope="scope">
                {{scope.row.sort}}
                <!-- <el-input v-model="scope.row.sort" @change="editItemsSort(scope.$index, scope.row)" size="mini"></el-input> -->
              </template>
            </el-table-column>
            <!-- <el-table-column prop="store" label="库存" width="80"></el-table-column> -->
            <el-table-column label="原价" width="80">
              <template slot-scope="scope">
                ¥{{scope.row.market_price}}
              </template>
            </el-table-column>
            <el-table-column label="销售参考价格" width="80">
              <template slot-scope="scope">
                ¥{{scope.row.price}}
              </template>
            </el-table-column>
            <el-table-column label="状态" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.audit_status == 'processing'">等待审核</span>
                <el-popover v-else-if="scope.row.audit_status == 'rejected'" placement="top-start" width="200" trigger="hover" :content="scope.row.audit_reason">
                  <el-button type="text" slot="reference">审核驳回</el-button>
                </el-popover>
                <span v-else-if="scope.row.approve_status == 'onsale' ">前台可销</span>
                <!-- <span v-else-if="scope.row.approve_status == 'offline_sale' ">可线下销售</span> -->
                <span v-else-if="scope.row.approve_status == 'only_show' ">前台仅展示</span>
                <span v-else>不可销售</span>

                <!-- <span v-if="login_type == 'admin-del' || login_type == 'dealer' || login_type == 'staff-del'  || login_type == 'disti'">
                  <el-button type="text" v-if="scope.row.itemIsActivePage == '1'" @click="submitItemIsActivePage(scope.row)" class="btn-gap" >切换普通页面</el-button>
                  <el-button type="text" v-if="scope.row.itemIsActivePage !== '1'" @click="submitItemIsActivePage(scope.row)" class="btn-gap" >切换活动页面</el-button>
                </span> -->
                <br>
                <el-tag v-if="scope.row.auto_receiving" type="success" size="mini">支持自动接单</el-tag>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="itemMainCatName" label="主类目" width="150"></el-table-column> -->
            <el-table-column prop="itemCatName" label="商品分类" width="150"></el-table-column>
          </el-table>
          <div class="content-center content-top-padded">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              :current-page.sync="params.page"
              :page-sizes="[10,20,50]"
              :total="total_count"
              :page-size="params.pageSize">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 选择运费模板-开始 -->
      <!-- <el-dialog
        title="更改运费模板"
        :visible.sync="addTemplatesdialogVisible"
        width="30%">
        <el-select v-model="templates_new_id" placeholder="运费模板" style="width: 100%;">
          <el-option v-for="item in templatesList" :key="item.template_id" :label="item.name" :value="item.template_id"></el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addTemplatesdialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="changeTemplates">确 定</el-button>
        </span>
      </el-dialog> -->
      <!-- 选择运费模板-结束 -->
      <!-- 选择商品分类-开始 -->
      <el-dialog
        title="更改商品分类"
        :visible.sync="addCategorydialogVisible"
        width="30%">
        <treeselect
          :options="categoryList"
          :multiple="true"
          :show-count="true"
          :disable-branch-nodes="true"
          v-model="category_id"
        >
        </treeselect>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addCategorydialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="changeCategory">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="为商品打标签" class="right-dialog" :visible.sync="tag.dialog" :before-close="handleCancelLabelsDialog">
        <div class="tag-users view-flex view-flex-middle">
          <i class="iconfont icon-user-circle1"></i>
          <div class="view-flex-item">
            <span v-for="item in tag.editItem"> {{item}}，</span>
          </div>
        </div>
        <div class="selected-tags view-flex">
          <div class="label">已选中标签：</div>
          <div class="view-flex-item">
            <el-tag :key="index" v-for="(tag, index) in tag.currentTags" closable size="small" :disable-transitions="false" @close="tagRemove(index)">
              {{tag.tag_name}}
            </el-tag>
          </div>
        </div>
        <div>
          <el-tag class="tag-item" :key="index" v-for="(tag, index) in tag.tags" size="medium" color="#ffffff" :disable-transitions="false" @click.native="tagAdd(tag, index)">
            {{tag.tag_name}}
          </el-tag>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="tag.dialog = false">取 消</el-button>
          <el-button type="primary" @click="submitItemTag">确 定</el-button>
        </span>
      </el-dialog>
      <SideBar :visible.sync="show_rebate_sideBar" title="商品分销配置" width="60">
        <slot>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span v-if="popularizeSetting.popularize_ratio.type == 'profit'">
                <el-alert title="返佣计算类型: 【按利润分佣】"
                  description="计算方式：商品利润 ×  百分比，其中商品利润为【支付金额-运费-商品成本价】，如果设置的返佣为固定金额，则不会按利润返佣，返佣金额就是设置的固定金额。如果不填则使用通用配置返佣"
                  type="info" close-text=" " class="alert-text" show-icon>
                </el-alert>
              </span>
              <span v-else>
                <el-alert title="返佣计算类型: 【按订单金额分佣】"
                  description="计算方式： 订单金额 × 百分比，其中订单金额为【支付金额-运费】，如果设置的返佣为固定金额，则不会按订单金额分佣，返佣金额就是设置的固定金额。如果不填则使用通用配置返佣"
                  type="info" close-text=" " class="alert-text" show-icon>
                </el-alert>
              </span>
            </div>
            商品名称：{{current.item_name}}
            <el-table :data="rebateSpecItems" v-loading="skuLoading">
              <el-table-column label="规格" prop="item_spec_desc" min-width="120">
                <template slot-scope="scope"><span v-if="scope.row.item_spec_desc">{{scope.row.item_spec_desc}}</span><span v-else>单规格</span></template>
              </el-table-column>
              <el-table-column label="销售参考价" min-width="80"> <template slot-scope="scope"> ¥{{scope.row.price/100}} </template> </el-table-column>
              <el-table-column label="成本价" min-width="80"> <template slot-scope="scope"> ¥{{scope.row.cost_price/100}} </template> </el-table-column>
              <el-table-column label="类型" width="160">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.rebate_conf.type" active-value="money" inactive-value="ratio" active-text="金额" inactive-text="比例"> </el-switch>
                </template>
              </el-table-column>
              <el-table-column v-for="(item,key) in popularizeSetting.popularize_ratio.profit" :key="key" :label="item.name" min-width="110">
                <template slot-scope="scope">
                  <el-input v-if="scope.row.rebate_conf.type == 'money'" type="number" v-model="scope.row.rebate_conf.value[key]" size="mini" suffix-icon="iconfont icon-yen-sign"></el-input>
                  <el-input v-else v-model="scope.row.rebate_conf.value[key]" size="mini" type="number" suffix-icon="iconfont icon-percent"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </slot>
        <div slot="footer"><el-button type="primary" @click="saveRebateConf" :loading="submitLoading">保存</el-button></div>
      </SideBar>
      <SideBar :visible.sync="show_profit_sideBar" name="show_profit_sideBar" title="商品分销配置" width="60">
        <slot>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>
                <el-alert
                  title="导购分润计算类型: 【默认】"
                  description="计算方式：默认按照 主类目分润配置优先,导购分润配置计算其次"
                  type="info"
                  close-text=" "
                  class="alert-text"
                  show-icon
                ></el-alert>
              </span>
              <span>
                <el-alert
                  title="导购分润计算类型: 【百分比】"
                  description="计算方式：商品最终金额 ×  百分比，其中计算方式：商品最终金额为【支付金额-运费-商品优惠金额】"
                  type="info"
                  close-text=" "
                  class="alert-text"
                  show-icon
                ></el-alert>
              </span>
              <span>
                <el-alert
                  title="导购分润计算类型: 【商品金额】"
                  description="计算方式： 固定金额分佣"
                  type="info"
                  close-text=" "
                  class="alert-text"
                  show-icon
                ></el-alert>
              </span>
            </div>
            商品名称：{{current.item_name}}
            <el-table :data="profitSpecItems" v-loading="skuLoading">
              <el-table-column label="规格" prop="item_spec_desc" min-width="120"></el-table-column>
              <el-table-column label="原价" prop="market_price" width="100">
                <template slot-scope="scope">¥{{scope.row.market_price}}</template>
              </el-table-column>
              <el-table-column label="销售参考价" width="100">
                <template slot-scope="scope">¥{{scope.row.price}}</template>
              </el-table-column>
              <el-table-column label="分润类型">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.profit_type" placeholder="请选择">
                    <el-option label="默认" :value="0"></el-option>
                    <el-option label="百分比" :value="1"></el-option>
                    <el-option label="商品金额" :value="2"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="拉新导购分润">
                <template slot-scope="scope">
                  <div v-if="0 == scope.row.profit_type">
                    <el-input
                      :disabled="true"
                      size="mini"
                      type="number"
                      value="0">
                    </el-input>
                  </div>
                  <div v-else>
                    <el-input v-model="scope.row.profit_conf_profit" size="mini" type="number">
                      <template v-if="1 == scope.row.profit_type" slot="append">%</template>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="推广导购分润">
                <template slot-scope="scope">
                  <div v-if="0 == scope.row.profit_type">
                    <el-input
                      :disabled="true"
                      size="mini"
                      type="number"
                      value="0"
                    >
                    </el-input>
                  </div>
                  <div v-else>
                    <el-input v-model="scope.row.profit_conf_popularize_profit" size="mini" type="number">
                      <template v-if="1 == scope.row.profit_type" slot="append">%</template>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </slot>
        <div slot="footer">
          <el-button type="primary" @click="saveProfitConf" :loading="submitLoading">保存</el-button>
        </div>
      </SideBar>
      <SideBar :visible.sync="show_sideBar" title="设置会员价" width="60">
        <slot>
          <el-table :data="specItems" v-loading="skuLoading" height="100%">
            <el-table-column label="规格" prop="item_spec_desc" min-width="120">
            </el-table-column>
            <el-table-column label="原价" prop="market_price" width="100">
              <template slot-scope="scope">
                ¥{{scope.row.market_price}}
              </template>
            </el-table-column>
            <el-table-column label="销售参考价" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.item_id !== currentId">
                  ¥{{scope.row.price}}
                  <i class="el-input__icon el-icon-edit" @click="editPrice(scope.row.item_id, scope.row.price)"></i>
                </div>
                <el-input v-else v-model="currentPrice" @blur="handleBlur(scope.$index)" size="mini" :ref="'input_' + scope.row.item_id"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="会员">
              <el-table-column v-for="(item, index) in grade" :key="index" :label="item.grade_name">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.grade[index].mprice" size="mini" type="number"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="付费会员">
              <el-table-column v-for="(item, index) in vipGrade" :key="index" :label="item.grade_name">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.vipGrade[index].mprice" size="mini" type="number"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </slot>
        <div slot="footer"><el-button type="primary" @click="savePrice" :loading="submitLoading">保存</el-button></div>
      </SideBar>
      <!-- 选择商品分类-结束 -->
      <SideBar :visible.sync="show_itemStore" title="设置商品库存" width="60">
        <slot>
          <el-table :data="storeItemsList" v-loading="skuLoading" height="100%">
            <el-table-column label="规格" prop="item_spec_desc" min-width="120">
            </el-table-column>
            <el-table-column label="库存">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.store" size="mini" type="number"></el-input>
                </template>
            </el-table-column>
          </el-table>
        </slot>
        <div slot="footer"><el-button type="primary" @click="saveItemsStore" :loading="submitLoading">保存</el-button></div>
      </SideBar>

      <el-dialog
        title="批量修改库存"
        :visible.sync="storeUpdate"
        width="30%">
        统一库存：<el-input v-model="itemstore" size="mini" type="number"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="storeUpdate = false">取 消</el-button>
          <el-button type="primary" @click="saveItemsStore">确 定</el-button>
        </span>
      </el-dialog>

      <el-drawer
        title=""
        :visible.sync="itemSyncToShopDrawer"
        direction="rtl"
        size="70%"
        :before-close="itemSyncToShopDrawerHandleClose">
        <div style="padding: 0 20px">
          <el-tabs v-model="itemSyncToShopBy" type="card" @tab-click="changeItemSyncToShopBy">
            <el-tab-pane label="选择店铺" name="distributor"></el-tab-pane>
            <el-tab-pane label="选择经销商" name="dealer"></el-tab-pane>
            <!-- <el-tab-pane label="file" name="file">文件导入店铺</el-tab-pane> -->
          </el-tabs>
          <div style="padding-top: 12px">
            <template>
              <div v-show="itemSyncToShopBy != 'file'">
                <el-input clearable style="width: 300px" v-if="itemSyncToShopBy === 'distributor'" placeholder="AppleID" v-model="itemSyncToShopParams.shop_code">
                  <el-button slot="append" icon="el-icon-search" @click="getDistributorEasyList"></el-button>
                </el-input>
                <el-input clearable style="width: 300px" v-if="itemSyncToShopBy === 'dealer'" placeholder="经销商HQID" v-model="itemSyncToShopParams.dealer_hqid">
                  <el-button slot="append" icon="el-icon-search" @click="getDealerList"></el-button>
                </el-input>
<!--                <el-input clearable style="width: 300px" v-if="itemSyncToShopBy === 'dealer'" placeholder="渠道RTM" v-model="itemSyncToShopParams.channel_rtm">-->
<!--                  <el-button slot="append" icon="el-icon-search" @click="getDealerList"></el-button>-->
<!--                </el-input>-->
                <el-input clearable style="width: 300px" :placeholder="itemSyncToShopBy === 'distributor' ? '店铺名称' : '经销商名称'" v-model="itemSyncToShopParams.name">
                  <el-button slot="append" icon="el-icon-search" v-if="itemSyncToShopBy === 'distributor'" @click="getDistributorEasyList"></el-button>
                  <el-button slot="append" icon="el-icon-search" v-else-if="itemSyncToShopBy === 'dealer'" @click="getDealerList"></el-button>
                </el-input>
                <el-select v-show="itemSyncToShopBy==='distributor'" v-model="itemSyncToShopParams.dealerId" clearable filterable @change="changeDealerFilter" placeholder="输入经销商名称" :remote="true" :remote-method="remoteSearchDealer" :loading="dealerSearchLoading">
                  <el-option
                    v-for="item in filter_dealer_list"
                    :key="item.value"
                    :label="item.name"
                    :value="item.dealer_id">
                  </el-option>
                </el-select>
                <dealer-rtm-select @update="dealerRtmChange"></dealer-rtm-select>
<!--                <el-input v-if="itemSyncToShopBy === 'distributor'" clearable style="width: 300px" placeholder="经销商渠道RTM" v-model="itemSyncToShopParams.rtm">-->
<!--                  <el-button slot="append" icon="el-icon-search" @click="getDistributorEasyList"></el-button>-->
<!--                </el-input>-->
              </div>
              <div style="margin-top: 12px" v-show="itemSyncToShopBy != 'file'">
    <!--            <el-button type="primary">查看已选商品</el-button>-->
                <el-button type="primary" size="small" @click="selectedDistributorVisible=true">查看已选</el-button>
                <!-- /distiadmin/entity/goods/Itemssyncdistributorlog -->
                <el-button style="margin-left: 12px" v-if="login_type == 'admin-del' || login_type == 'staff-del' || login_type == 'disti' || login_type == 'dealer'" size="small" type="primary" icon="el-icon-s-shop" plain @click="showitemsSyncAllChecked" :loading="itemsSyncToShopLoading">一键同步所有</el-button>
                <el-popover
                  placement="top-start"
                  width="auto"
                  trigger="hover"
                  content="不局限于页面选中店铺，对所有店铺同步！">
                  <i class="el-icon-question" slot="reference"></i>
                </el-popover>
                <el-button  style="margin-left: 12px" v-if="login_type == 'admin-del' || login_type == 'staff-del' || login_type == 'disti' || login_type == 'dealer'" size="small" type="primary" icon="el-icon-s-shop" plain @click="showitemsSyncToShop">查看同步记录</el-button>
              </div>
              <div v-show="itemSyncToShopBy != 'file'">
                <el-table ref="distributorTable" :data="distributors" v-loading="itemSyncDistirbutorLoading" @selection-change="distributorSelectCurrentChange" row-key="distributor_id" v-show="itemSyncToShopBy == 'distributor'">
                  <el-table-column type="selection" :reserve-selection="true"></el-table-column>
                  <el-table-column label="ID" prop="distributor_id" width="60"></el-table-column>
                  <el-table-column label="AppleId" prop="shop_code" width="120"></el-table-column>
                  <el-table-column label="名称" prop="name"></el-table-column>
                  <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
                </el-table>
                <el-table ref="dealerTable" :data="dealers" v-loading="itemSyncDealerLoading" @selection-change="dealerSelectCurrentChange" row-key="dealer_id" v-show="itemSyncToShopBy == 'dealer'">
                  <el-table-column type="selection" :reserve-selection="true"></el-table-column>
                  <el-table-column label="ID" prop="dealer_id" width="60"></el-table-column>
                  <el-table-column label="名称" prop="name"></el-table-column>
                  <el-table-column label="经销商HQID" width="120" prop="dealer_hqid"></el-table-column>
                  <el-table-column label="分销商HQID" width="120" prop="distributor_hqid"></el-table-column>
                  <el-table-column label="分销商VAD" width="120" prop="distributor_vad"></el-table-column>
                  <el-table-column label="渠道RTM" width="120" prop="channel_rtm"></el-table-column>
                </el-table>
                <el-pagination
                  background
                  layout="total, sizes, prev, pager, next, jumper"
                  style="margin-top: 20px; text-align: center"
                  :page-sizes="[1, 20, 100, 200, 800]"
                  :page-size="itemSyncToShopParams.pageSize"
                  @size-change="handleDistributorSizeChange"
                  @current-change="handleDistributorCurrentChange"
                  :total="distributor_totalcount">
                </el-pagination>
              </div>
            </template>
          </div>
          <div style="margin-top: 20px; text-align: center">
            <el-button plain type="primary" @click="itemSyncToShopDrawer=false">取消</el-button>
            <el-button slot="reference" type="primary" style="margin-left: 20px" @click="itemsSyncToShop" :loading="itemsSyncToShopLoading">确认</el-button>
          </div>
        </div>
      </el-drawer>


      <el-drawer
        title="查看同步结果"
        :visible.sync="itemSyncToShopResult"
        direction="rtl"
        size="70%">
        <div style="padding: 0 20px">
          <div style="padding-top: 20px">
            <template>
              <div v-show="itemSyncToShopBy != 'file'">
                <el-table ref="distributorTable" :data="distributors" v-loading="itemSyncDistirbutorLoading" @selection-change="distributorSelectCurrentChange" row-key="distributor_id" v-show="itemSyncToShopBy == 'distributor'">
                  <el-table-column type="selection" :reserve-selection="true"></el-table-column>
                  <el-table-column label="ID" prop="distributor_id" width="60"></el-table-column>
                  <el-table-column label="AppleId" prop="shop_code" width="120"></el-table-column>
                  <el-table-column label="名称" prop="name"></el-table-column>
                  <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
                </el-table>
                <el-pagination
                  background
                  layout="total, sizes, prev, pager, next, jumper"
                  style="margin-top: 20px; text-align: center"
                  :page-sizes="[1, 20, 100]"
                  :page-size="itemSyncToShopParams.pageSize"
                  @size-change="handleDistributorSizeChange"
                  @current-change="handleDistributorCurrentChange"
                  :total="distributor_totalcount">
                </el-pagination>
              </div>
            </template>
          </div>
          <div style="margin-top: 20px; text-align: center">
            <el-button plain type="primary" @click="itemSyncToShopResult=false">取消</el-button>
            <el-button slot="reference" type="primary" style="margin-left: 20px" @click="itemSyncToShopResult" :loading="itemSyncToShopResultLoading">确认</el-button>
          </div>
        </div>
      </el-drawer>


      <el-dialog
        title="已选店铺"
        :visible.sync="selectedDistributorVisible"
        width="50%">
        <el-table v-if="itemSyncToShopBy === 'distributor'" :data="selected_distributor" v-loading="itemSyncDistirbutorLoading">
          <el-table-column label="ID" prop="distributor_id" width="60"></el-table-column>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="AppleId" prop="shop_code"></el-table-column>
          <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
        </el-table>
        <el-table v-else-if="itemSyncToShopBy === 'dealer'" :data="selected_dealer" v-loading="itemSyncDistirbutorLoading">
          <el-table-column label="ID" prop="dealer_id" width="60"></el-table-column>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="经销商HQID" width="120" prop="dealer_hqid"></el-table-column>
          <el-table-column label="分销商HQID" width="120" prop="distributor_hqid"></el-table-column>
          <el-table-column label="分销商VAD" width="120" prop="distributor_vad"></el-table-column>
          <el-table-column label="渠道RTM" width="120" prop="channel_rtm"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="selectedDistributorVisible = false">关 闭</el-button>
        </span>
      </el-dialog>

      <!-- 预览 -->
      <el-dialog
        title="预览"
        :visible.sync="previewVisible"
        :before-close="handleClosePreviewDialog"
        width="30%">
        <div style="text-align: center" v-loading="imgLoading">
          <div>
            请使用手机扫码预览
          </div>
          <div class="block">
            <el-image
              style="width: 200px; height: 200px"
              :src="previewImgSrc">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="previewVisible = false">关闭</el-button>
      </span>
      </el-dialog>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { getPopularizeSetting } from '@/api/promotions'
  // import the component
  import Treeselect from '@riophae/vue-treeselect'
  import SideBar from '@/components/element/sideBar'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { Message } from 'element-ui'
  import { getShippingTemplatesList, getGoodsOmsinfo } from '@/api/shipping'
  import { getDefaultCurrency } from '@/api/company'
  import district from '@/common/district.json'
  import dealerRtmSelect from "@/components/dealerRtmSelect";
  import {
    getItemsList,
    setItemsTemplate,
    setItemsCategory,
    deleteItems,
    getItemWarningStore,
    getCategory,
    setItemsSort,
    updateGoodsInfo,
    getGoodsPrice,
    updateGoodsPrice,
    updateItemRebateConf,
    getTagList,
    itemsRelTags,
    tagSearchItem,
    updateItemsStore,
    updateItemsStatus,
    updateItemsAuthReceiving,
    getGoodsAttr,
    getGoodsExport,
    exportItemsData,
    exportRecommendItemsData,
    getGoodsProfitPrice,
    saveGoodsProfitPrice,
    syncItems,
    saveIsGifts,
    itemSyncToDistributor,
    getGoodsPreviewQrCode,
    updateEduItemsParam,
    itemIsActivePage,
  } from '@/api/goods'
  import { getDistributorEasyList, getDealerList, getDistributorItems } from '@/api/marketing'
  import { useDialog } from '@shopex/finder'

  // 取选中地区的值
  function getCascaderObj(val, opt) {
    return val.map(function (value, index, array) {
      for (var itm of opt) {
        if (itm.value === value) {
          opt = itm.children
          return itm
        }
      }
      return null
    })
  }

export default {
    name: 'GoodsList',
    components: {
      Treeselect,
      dealerRtmSelect,
      SideBar
    },
    props: ['getStatus'],
    provide() {
      return {
        refresh: this.getGoodsList
      }
    },
    data () {
      return {
        previewVisible: false,
        imgLoading: false,
        previewImgSrc: '',
        login_type: 'default',
        show_rebate_sideBar: false,
        show_profit_sideBar: false,
        select_regions_value: [],
        select_category_value: [],
        select_tags_value: [],
        select_branch_value:[],
        special_type_list: [
          {value: 'normal', name:'普通商品'},
          {value: 'drug', name:'处方药'},
        ],
        popularizeSetting: {
          popularize_ratio: {
            type: 'profit'
          }
        },
        regions: district,
        current: '',
        currentId: '',
        currentPrice: '',
        tabList: [
          {name: "全部商品", value: null, activeName: 'first'},
          {name: "库存预警商品", value: 'true', activeName: 'second'},
        ],
        goodsName: '',
        isEdit: false,
        activeName: 'first',
        ItemsList: [],
        item_id: [],
        goods_id: [],
        templates_id: '',
        templates_new_id: '',
        templatesList: [],
        category_id: [],
        categoryList: [],
        show_sideBar: false,
        loading: false,
        skuLoading: false,
        submitLoading: false,
        total_count: 0,
        warning_store: 5,
        templatesParams: {
          page: 1,
          pageSize: 1000,
        },
        goodsBranchList:[],
        goodsBranchParams: {
          page: 1,
          pageSize: 1000,
          attribute_type: 'brand',
          attribute_name:''
        },
        tag: {
          dialog: false,
          editItem: [],
          list: [],
          form: {
            tag_ids: [],
            item_ids: []
          },
          currentTags: [],
          tags: []
        },
        params: {
          page: 1,
          pageSize: 20,
          item_type: 'normal',
          templates_id: '',
          keywords: '',
          category: 0,
          is_warning: false,
          tag_id: '',
          is_gift: 'all',
          type: 0,
          has_recommend: '',
          is_tcb: '',
          type_warehouse: '',
          auto_receiving: ''
        },
        start_date: '',
        end_date: '',
        addTemplatesdialogVisible: false,
        addCategorydialogVisible: false,
        currency: {},
        cursymbol: '￥',
        form: {},
        isGiftsData: {},
        exportData: {},
        specItems: [],
        rebateSpecItems: [],
        profitSpecItems: [],
        grade: [],
        vipGrade: [],
        statusOption: [
          { title: '前台可销售', value: 'onsale' },
          // { title: '可线下销售', value: 'offline_sale' },
          { title: '前台仅展示', value: 'only_show' },
          { title: '不可销售', value: 'instock' }
        ],
        shopStatusOption: [
          { title: '审核驳回', value: 'rejected'},
          { title: '等待审核', value: 'processing'},
          { title: '前台可销售', value: 'onsale' },
          // { title: '可线下销售', value: 'offline_sale' },
          { title: '前台仅展示', value: 'only_show' },
          { title: '不可销售', value: 'instock' }
        ],
        storeUpdate: false,
        storeItemsList: [],
        show_itemStore: false,
        itemstore: 0,
        distributorVisible: false,
        setDistributorStatus: false,
        relDistributorIds: '',

        itemSyncToShopResult: false,
        itemSyncToShopResultLoading: false,

        itemSyncToShopDrawer: false,
        itemSyncToShopBy: 'distributor',
        itemSyncToShopParams: {
          name: '',
          province: '',
          city: '',
          area: '',
          page: 1,
          pageSize: 20,
          dealerId: '',
          shop_code: '',
        },
        filter_dealer_list: [],
        distributors: [],
        dealers: [],
        distributor_totalcount: 0,
        selected_distributor: [],
        selected_distributor_ids: [],
        selected_dealer: [],
        selected_dealer_ids: [],
        itemSyncDistirbutorLoading: false,
        itemSyncDealerLoading: false,
        itemsSyncToShopLoading: false,
        selectedDistributorVisible: false,
        selectedDealerVisible: false,
        distributorTableRef: {},
        dealerTableRef: {},
        dealerSearchLoading: false,
        dealerRtmData: [],
        specDescItems: []
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    },
    methods: {
      init () {
        if (this.$route.path.split('/')[2] === 'godsphysicalkj') {
          console.log('跨境商品列表')
          this.params.type = 1
        } else {
          console.log('普通商品列表')
          this.params.type = 0
        }
        this.login_type = this.$store.getters.login_type
        if (this.$route.query.category) {
          this.params.category = this.$route.query.category
        }
        this.params.main_cat_id = this.$route.query.main_cat_id

        getPopularizeSetting().then(response => {
          this.popularizeSetting = response.data.data
        })
        if (this.$route.query.tab) {
          this.activeName = this.$route.query.tab
          this.params.is_warning = true
        }

        this.getGoodsList()
        this.getShippingTemplatesList()
        this.getCategory()
        this.getCurrencyInfo()
        this.getAllTagLists()
        this.getGoodsBranchList()
      },
      batchGifts(status) {
        if (this.item_id.length) {
          this.isGiftsData.item_id = Object.assign({}, this.item_id);
          this.isGiftsData.status = status
          saveIsGifts(this.isGiftsData).then(res => {
            if (res.data.data.status == true) {
              this.$message({
                type: 'success',
                message: '操作成功'
              });
            } else {
              this.$message({
                type: 'error',
                message: '操作失败'
              });
            }
          });
        } else {
          this.$message({
            type: 'error',
            message: '请选择至少一个商品!'
          })
        }
      },
      exportItemsData() {
        if (this.item_id.length) {
          this.exportData.item_id = Object.assign({}, this.item_id);
          exportItemsData(this.exportData).then(res => {
            if (res.data.data.status == true) {
              this.$message({
                type: 'success',
                message: '已加入执行队列，请在设置-导出列表中下载'
              });
            } else {
              this.$message({
                type: 'error',
                message: '导出失败'
              });
            }
          });
        } else {
          this.exportData = Object.assign({}, this.params)
          exportItemsData(this.exportData).then(res => {
            if (res.data.data.status == true) {
              this.$message({
                type: 'success',
                message: '已加入执行队列，请在设置-导出列表中下载'
              });
            } else {
              this.$message({
                type: 'error',
                message: '导出失败'
              });
            }
          });
        }
      },
      exportRecommendItemsData() {
        const exportData = {
          item_id: this.item_id
        }
        exportRecommendItemsData(exportData).then(res => {
          if (res.data.data.status == true) {
            this.$message({
              type: 'success',
              message: '已加入执行队列，请在设置-导出列表中下载'
            });
          } else {
            this.$message({
              type: 'error',
              message: '导出失败'
            })
          }
        })
      },
      syncItems() {
        syncItems().then(res => {
          if (res.data.data.status == true) {
            this.$message({
              type: 'success',
              message: '已加入执行队列'
            });
          } else {
            this.$message({
              type: 'error',
              message: '同步失败'
            });
          }
        });
      },
      searchAction() {
        this.params.page = 1
        if (this.select_regions_value) {
          this.params.regions_id = this.select_regions_value
        }

        this.params.category = this.select_category_value.pop()

        if (this.select_tags_value) {
          this.params.tag_id = this.select_tags_value[0]
        }
        this.params.brand_id = this.select_branch_value
        this.getGoodsList()
      },
      onCopy () {
        this.$notify.success({
          message: '复制成功',
          showClose: true
        });
      },
      editPrice(id, price) {
        this.currentId = id
        this.currentPrice = price
        let self = this
        this.$nextTick(() => {
          self.$refs['input_' + id].focus()
        })
      },
      handleBlur(index) {
        if (this.currentPrice === this.specItems[index].price) {
          this.currentId = -1
          this.currentPrice = ''
          return
        }
        updateGoodsInfo({item_id: this.currentId, price: this.currentPrice}).then(res => {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.specItems[index].price = this.currentPrice
          this.currentId = -1
          this.currentPrice = ''
        })
      },
      handleClick (tab, event) {
        this.params.page = 1
        if(this.activeName == 'second') {
          this.params.is_warning = true
        } else {
          this.params.is_warning = false
        }
        this.getGoodsList()
      },
      setWarningStore () {
        getItemWarningStore({store: this.warning_store}).then(res => {
          this.params.page = 1
          this.getGoodsList()
        })
      },
      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getGoodsList()
      },
      handleSizeChange (pageSize) {
        this.params.page = 1
        this.params.pageSize = pageSize
        this.getGoodsList()
      },
      handleChangeTemplates (val) {
        this.currentPage = 1
        this.params.templates_id = val
        this.getGoodsList()
      },
      handleSelectionChange (val) {
        let item_id = []
        for (let i in val) {
          item_id.push(val[i].itemId)
        }
        this.item_id = item_id
        let goods_id = []
        for (let i in val) {
          goods_id.push(val[i].goods_id)
        }
        this.goods_id = goods_id
      },
      handleCancelLabelsDialog() {
        this.tag.dialog = false
      },
      tagUpdate(row) {
        this.tag.editItem = [row.itemName]
        this.tag.currentTags = row.tagList || []
        this.tag.form.item_ids = row.item_id
        this.showTags()
      },
      addItemTag() {
        this.tag.currentTags = []
        if (this.item_id.length) {
          this.showTags()
          this.tag.form.item_ids = this.item_id
        } else {
          this.$message({
            type: 'error',
            message: '请选择至少一个商品!'
          })
        }
      },
      showTags() {
        this.tag.tags = [...this.tag.list]
        this.tag.tags.forEach((item, index) => {
          let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
          if (isInArr != -1) this.tag.tags.splice(index, 1)
        })
        this.tag.dialog = true
      },
      tagRemove(index) {
        this.tag.tags.unshift(this.tag.currentTags[index])
        this.tag.currentTags.splice(index, 1)
      },
      tagAdd(item, index) {
        let isInArr = this.tag.currentTags.findIndex(n => n.tag_id == item.tag_id)
        if (isInArr == -1) {
          this.tag.currentTags.push(item)
          this.tag.tags.splice(index, 1)
        }
      },
      submitItemTag() {
        this.tag.form.tag_ids = []
        this.tag.currentTags.forEach(item => {
          this.tag.form.tag_ids.push(item.tag_id)
        })
        this.tag.dialog = false
        itemsRelTags(this.tag.form).then(res => {
          if (res.data.data.status) {
            this.$message({
              type: 'success',
              message: '打标签完成'
            })
            this.getGoodsList()
          }
        })
      },

      submitItemIsActivePage(items) {
        this.loading = true
        let params = {}
        params = {
          'items': [{"goods_id":items.goods_id}],
          'status': items.itemIsActivePage == '1' ? '0' : '1'
        }
        itemIsActivePage(params).then(res => {
          if (res.data.data.status) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 3 * 1000
            })
            this.getGoodsList()
          }
        })
        this.loading = false
      },


      tagSearchUserChange() {
        this.currentPage = 1
        this.getParams()
        this.getItemsList(this.params)
        this.loading = false
      },
      getAllTagLists() {
        let params = {
          page: 1,
          pageSize: 500
        }
        getTagList(params).then(response => {
          this.tag.list = response.data.data.list
        })
      },
      changeTemplates () {
        if(this.item_id.length) {
          if(!this.templates_new_id) {
            this.$message({
              type: 'error',
              message: '请选择运费模板'
            })
            return false
          }
          this.addTemplatesdialogVisible = false
          setItemsTemplate({"templates_id": this.templates_new_id, "item_id": this.item_id}).then(response => {
            this.getGoodsList()
          })
        } else {
          this.$message({
            type: 'error',
            message: '请选择至少一个商品'
          })
        }
      },
      editItemsSort (index, row) {
        setItemsSort({"sort": row.sort, "item_id": row.itemId}).then(response => {
          this.getGoodsList()
        })
      },
      changeCategory () {
        if(this.item_id.length) {
          if(!this.category_id) {
            this.$message({
              type: 'error',
              message: '请选择商品分类'
            })
            return false
          }
          this.addCategorydialogVisible = false
          setItemsCategory({"category_id": this.category_id, "item_id": this.item_id}).then(response => {
            this.getGoodsList()
            this.category_id = []
          })
        } else {
          this.$message({
            type: 'error',
            message: '请选择至少一个商品'
          })
        }
      },
      addItems () { // 添加商品
        this.$router.push({path: this.matchHidePage('editor')})
      },
      addTemplates () {
        if(this.item_id.length) {
          this.addTemplatesdialogVisible = true
        } else {
          this.$message({
            type: 'error',
            message: '请选择至少一个商品'
          })
        }
      },
      addCategory () {
        if(this.item_id.length) {
          this.addCategorydialogVisible = true
        } else {
          this.$message({
            type: 'error',
            message: '请选择至少一个商品'
          })
        }
      },
      editItemsAction (index, row, isNew) { // 编辑商品弹框
        this.show_rebate_sideBar = false
        this.show_sideBar = false
        this.show_itemStore = false
        if (isNew) {
          var routeData = this.$router.push({ path: this.matchHidePage('editor/') + row.itemId, query: { is_new: true}});
        } else {
          var routeData = this.$router.push({ path: this.matchHidePage('editor/') + row.itemId});
        }
      },
      saveRebateConf() {
        var rebateConf = []
        this.rebateSpecItems.forEach(item => {
          var rebate_conf = item.rebate_conf
          rebate_conf.ratio_type = this.popularizeSetting.popularize_ratio.type
          rebateConf.push(rebate_conf)
        })
        updateItemRebateConf({rebateConf: JSON.stringify(rebateConf)}).then(res => {
          this.$message({ message: '保存成功', type: 'success', duration: 2 * 1000 })
        })
      },
      saveProfitConf() {
        var profitConf = [];
        this.profitSpecItems.forEach((item) => {
          var profit_conf = {};
          profit_conf.item_id = item.item_id;
          profit_conf.profit_type = item.profit_type;
          profit_conf.price = item.price * 100;
          if (1 == profit_conf.profit_type) {
            profit_conf.profit_conf_profit = item.profit_conf_profit;
            profit_conf.profit_conf_popularize_profit =
            item.profit_conf_popularize_profit;
          } else {
            profit_conf.profit_conf_profit = item.profit_conf_profit * 100;
            profit_conf.profit_conf_popularize_profit =
            item.profit_conf_popularize_profit * 100;
          }

          profitConf.push(profit_conf);
        });
        saveGoodsProfitPrice({ profit_conf: JSON.stringify(profitConf) }).then(
          (res) => {
            this.$message({
              message: "保存成功",
              type: "success",
              duration: 2 * 1000,
            });
          }
        );
      },
      handleRebateConf(data) {
        this.show_rebate_sideBar = true
        this.show_profit_sideBar = false;
        this.show_sideBar = false
        this.skuLoading = true
        this.current = data
        getItemsList({page:1, pageSize:1000, is_sku:true, item_id:data.item_id, item_type: 'normal'}).then(res => {
          var rebateSpecItems = []
          res.data.data.list.forEach(item => {
            if (item.rebate_conf.length === 0 ) {
              item.rebate_conf = {}
              item.rebate_conf.type = 'money'
              item.rebate_conf.value = {}
              for( var key in this.popularizeSetting.popularize_ratio.profit ) {
                item.rebate_conf.value[key] = ''
              }
            } else {
              if (this.popularizeSetting.popularize_ratio.type != item.rebate_conf.ratio_type && item.rebate_conf.type != 'money') {
                for( var key in this.popularizeSetting.popularize_ratio.profit ) {
                  item.rebate_conf.value[key] = ''
                }
              }
            }
            item.rebate_conf.item_id = item.item_id
            rebateSpecItems.push(item)
          })
          this.rebateSpecItems = rebateSpecItems
          this.skuLoading = false
        })
      },
      handlePrice(data) {
        this.show_rebate_sideBar = false
        this.show_sideBar = true
        this.show_profit_sideBar = false;
        this.current = data.item_id
        this.getGoodsPrice(data.item_id)
      },
      handleProfitPrice(data) {
        this.show_rebate_sideBar = false;
        this.show_sideBar = false;
        this.show_profit_sideBar = true;
        this.current = data.item_id;
        this.getGoodsProfitPrice(data.item_id);
      },
      getGoodsPrice(item_id) {
        this.skuLoading = true
        getGoodsPrice(item_id).then(res => {
          let specItems = []
          let colConfigs = []
          res.data.data.list.forEach(item => {
            specItems.push({
              item_id: item.item_id,
              item_spec_desc: item.item_spec_desc || item.itemName,
              price: item.price/100,
              market_price: item.market_price/100,
              grade: this.generatePrice(item.memberGrade.grade),
              vipGrade: this.generatePrice(item.memberGrade.vipGrade)
            })
          })
          this.grade = this.generatePrice(res.data.data.list[0].memberGrade.grade)
          this.vipGrade = this.generatePrice(res.data.data.list[0].memberGrade.vipGrade)
          this.specItems = specItems
          this.skuLoading = false
        })
      },
      getGoodsProfitPrice(item_id) {
        this.skuLoading = true;
        getGoodsProfitPrice(item_id).then((res) => {
          let profitSpecItems = [];
          let colConfigs = [];
          res.data.data.list.forEach((item) => {
            let arr = {
              item_id: item.item_id,
              item_spec_desc: item.item_spec_desc || item.itemName,
              price: item.price / 100,
              market_price: item.market_price / 100,
              profit_type: item.profit_type,
              profit_conf_profit: item.profit_conf_profit,
              profit_conf_popularize_profit: item.profit_conf_popularize_profit,
            }
            if (2 == item.profit_type) {
              arr.profit_conf_profit = item.profit_conf_profit / 100
              arr.profit_conf_popularize_profit = item.profit_conf_popularize_profit / 100
            }
            profitSpecItems.push(arr);
          });
          this.profitSpecItems = profitSpecItems;
          this.skuLoading = false;
        });
      },
      generatePrice(data) {
        let list = []
        for (let key in data) {
          list.push({
            vip_grade_id: data[key].vip_grade_id,
            grade_name: data[key].grade_name,
            mprice: data[key].mprice/100 || '',
          })
        }
        return list
      },
      savePrice() {
        let param = {
          item_id: this.current,
          mprice: ''
        }
        let skus = {}
        this.specItems.forEach(item => {
          let grade = {}
          item.grade.forEach(child => {
            Object.assign(grade, {[child.vip_grade_id]: child.mprice})
          })
          let vipGrade = {}
          item.vipGrade.forEach(child => {
            Object.assign(vipGrade, {[child.vip_grade_id]: child.mprice})
          })
          Object.assign(skus, {[item.item_id]: {
            grade: grade,
            vipGrade: vipGrade
          }})
        })
        param.mprice = JSON.stringify(skus)

        this.submitLoading = true
        const _self = this
        updateGoodsPrice(param).then(res => {
          this.$message({
            type: 'success',
            message: '更新成功',
            duration: 2000,
            onClose() {
              _self.submitLoading = false
              _self.getGoodsPrice(param.item_id)
            }
          })
        }).catch(res => {
          _self.submitLoading = false
        })
      },
      goodsSearch () {
        this.params.page = 1
        this.getGoodsList()
      },
      getGoodsList () {
        this.loading = true
        let params = JSON.parse(JSON.stringify(this.params))
        if (params.is_gift == 'all') {
          this.$delete(params, 'is_gift')
        }
        getItemsList(params).then(response => {
          this.ItemsList = response.data.data.list
          this.ItemsList.forEach(item => {
            item.price = item.price/100
            item.market_price = item.market_price/100
            item.link = `pages/item/espier-detail?gid=${item.goods_id}&id=${item.item_id}`
          })
          this.total_count = response.data.data.total_count
          this.warning_store = response.data.data.warning_store
          this.loading = false
        })
      },
      deleteItemsAction (index, row) {
        this.$confirm('此操作将删除该商品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteItems(row.itemId).then(response => {
            this.ItemsList.splice(index, 1)
            this.$message({
              message: '删除商品成功',
              type: 'success',
              duration: 2 * 1000
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      },
      priceformatter(row, column) {
        return this.cursymbol + row.price / 100;
      },
      rebateformatter(row, column) {
        return this.cursymbol + row.rebate / 100;
      },
      getTaskTime (strDate) {
          let date = new Date(strDate)
          let y = date.getFullYear()
          let m = date.getMonth() + 1
          m = m < 10 ? ('0' + m) : m
          let d = date.getDate()
          d = d < 10 ? ('0' + d) : d
          let str = y+"-"+m+"-"+d
          return str
      },
      getTimeStr (date) {
        return this.getTaskTime(new Date(parseInt(date) * 1000))
      },
      getShippingTemplatesList () {
        this.loading = true
        getShippingTemplatesList(this.templatesParams).then(response => {
          this.templatesList = response.data.data.list
        })
      },
      getGoodsBranchList (searchVal = '') {
        // this.loading = true
        // console.log(searchVal)
        this.goodsBranchParams.attribute_name = searchVal
        getGoodsAttr(this.goodsBranchParams).then(response => {
          this.goodsBranchList = response.data.data.list
          // console.log(this.goodsBranchList)
        })
      },
      getCategory() {
        getCategory({is_show:false}).then(response => {
          this.categoryList = response.data.data
        })
      },
      getCurrencyInfo() {
        getDefaultCurrency().then(res => {
          this.currency = res.data.data
          this.cursymbol = this.currency.symbol
        })
      },
      batchItemsStore () {
        this.storeItemsList = []
        if(this.item_id.length) {
          this.storeUpdate = true
        } else {
          this.$message({
            type: 'error',
            message: '请选择至少一个商品!'
          })
        }
      },
      batchItemsStatus (status) {
        if (this.goods_id.length === 0) {
          this.$message({
            type: 'error',
            message: '请选择至少一个商品~'
          })
          return  false
        }
        this.skuLoading = true
        let params = {}
        if (this.goods_id.length > 0) {
          let data = []
          this.goods_id.forEach(goods_id => {
            data.push({goods_id: goods_id})
          })
          params = {
            'items': JSON.stringify(data),
            'status': status
          }
        }
        this.submitLoading = true
        updateItemsStatus(params).then(res => {
          if (res.data.data.status) {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 2 * 1000
            })
            this.getGoodsList()
          }
          this.submitLoading = false
          this.skuLoading = false
        })
      },
      batchItemsAuthReceiving (auto_receiving) {
        if (this.goods_id.length === 0) {
          this.$message({
            type: 'error',
            message: '请选择至少一个商品~'
          })
          return  false
        }
        this.skuLoading = true
        let params = {}
        if (this.goods_id.length > 0) {
          let data = []
          this.goods_id.forEach(goods_id => {
            data.push({goods_id: goods_id})
          })
          params = {
            'items': JSON.stringify(data),
            'auto_receiving': auto_receiving
          }
        }
        this.submitLoading = true
        updateItemsAuthReceiving(params).then(res => {
          if (res.data.data.status) {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 2 * 1000
            })
            this.getGoodsList()
          }
          this.submitLoading = false
          this.skuLoading = false
        })
      },
      updateItemStatus (items) {
        this.loading = true
        let params = {}
        params = {
          'items': [{"goods_id":items.goods_id}],
          'status': items.approve_status === 'instock' ? 'onsale' : 'instock'
        }
        updateItemsStatus(params).then(res => {
          if (res.data.data.status) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 2 * 1000
            })
            this.getGoodsList()
          }
        })
        this.loading = false
      },
      updateItemsStore (items) {
        this.skuLoading = true
        this.item_id = []
        let param = JSON.parse(JSON.stringify(this.params))
        param.item_id = items.item_id
        param.is_sku = true
        this.storeItemsList = []
        getItemsList(param).then(response => {
          let list = response.data.data.list
          let data = {}
          list.forEach(item => {
            let data = {
              item_id: item.item_id,
              store: item.store,
              item_spec_desc: item.item_spec_desc ? item.item_spec_desc : item.item_name
            }
            this.storeItemsList.push(data)
          })
          this.skuLoading = false
        })
        this.show_itemStore = true
      },
      saveItemsStore(){
        this.skuLoading = true
        let params = {}
        if (this.storeItemsList.length > 0) {
          params = {
            'items': JSON.stringify(this.storeItemsList)
          }
        } else if (this.item_id.length > 0) {
          let data = []
          this.item_id.forEach(itemid => {
            data.push({item_id: itemid, store: this.itemstore, is_default: true})
          })
          params = {
            'items': JSON.stringify(data)
          }
        }
        this.submitLoading = true
        const _self = this
        updateItemsStore(params).then(res => {
          if (res.data.data.status) {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 2 * 1000
            })
          }
          this.submitLoading = false
          this.skuLoading = false
          this.storeUpdate = false
        })
      },
      showitemsSyncAllChecked (){
        this.itemsSyncToShop(true)
      },
      showitemsSyncToShop () {
        // if (this.goods_id.length <= 0) {
        //   this.$message({
        //     type: 'error',
        //     message: '请选择要同步的商品'
        //   })
        //   return false;
        // }
        window.open(`/distiadmin/entity/goods/Itemssyncdistributorlog`)

        // this.getDistributorEasyList();
        // // this.getDealerList({page:1, pageSize:20})

        // this.itemSyncToShopResult = true;
      },

      handleitemsSyncToShop () {
        if (this.goods_id.length <= 0) {
          this.$message({
            type: 'error',
            message: '请选择要同步的商品'
          })
          return false;
        }

        this.getDistributorEasyList();
        // this.getDealerList({page:1, pageSize:20})

        this.itemSyncToShopDrawer = true;
      },
      itemSyncToShopDrawerHandleClose () {
        // this.$confirm('确认关闭？')
        //   .then(_ => {
        //     this.itemSyncToShopDrawer = false;
        //   })
        //   .catch(_ => {});
        this.itemSyncToShopDrawer = false;
      },
      getDistributorEasyList () {
        this.itemSyncDistirbutorLoading = true;
        const params = JSON.parse(JSON.stringify(this.itemSyncToShopParams));
        getDistributorEasyList(params).then(response => {
          this.distributors = response.data.data.list
          this.distributor_totalcount = response.data.data.total_count
          this.itemSyncDistirbutorLoading = false;
        }).catch(error => {
          this.itemSyncDistirbutorLoading = false;
        })
      },
      getDealerList () {
        this.itemSyncDealerLoading = true;
        let params = JSON.parse(JSON.stringify(this.itemSyncToShopParams));
        getDealerList(params).then(response => {
          this.dealers = this.filter_dealer_list = response.data.data.list
          this.distributor_totalcount = response.data.data.total_count
          this.itemSyncDealerLoading = false;
          this.dealerSearchLoading = false;
        }).catch(error => {
          this.itemSyncDealerLoading = false;
          this.dealerSearchLoading = false;
        })
      },
      dealerRtmChange(val) {
        this.itemSyncToShopParams.rtm = val.channel_rtm
        this.itemSyncToShopParams.channel_rtm = val.channel_rtm
        if (this.itemSyncToShopBy === 'distributor') {
          this.getDistributorEasyList();
        } else if (this.itemSyncToShopBy === 'dealer') {
          this.getDealerList();
        }
      },
      handleDistributorSizeChange (val) {
        this.itemSyncToShopParams.page = 1
        this.itemSyncToShopParams.pageSize = val
        if (this.itemSyncToShopBy === 'distributor') {
          this.getDistributorEasyList();
        } else if (this.itemSyncToShopBy === 'dealer') {
          this.getDealerList();
        }
      },
      handleDistributorCurrentChange (val) {
        this.itemSyncToShopParams.page = val
        if (this.itemSyncToShopBy === 'distributor') {
          this.getDistributorEasyList();
        } else if (this.itemSyncToShopBy === 'dealer') {
          this.getDealerList();
        }
      },
      distributorSelectCurrentChange (val) {
        console.log(val)
        this.selected_distributor = val;

        let selected_distributor_ids = []
        for (let i in val) {
          selected_distributor_ids.push(val[i].distributor_id)
        }
        this.selected_distributor_ids = selected_distributor_ids
      },
      dealerSelectCurrentChange (val) {
        console.log(val)
        this.selected_dealer = val;

        let selected_dealer_ids = []
        for (let i in val) {
          selected_dealer_ids.push(val[i].dealer_id)
        }
        this.selected_dealer_ids = selected_dealer_ids
      },
      itemsSyncToShop (allStoreChecked) {
        this.itemsSyncToShopLoading = true;
        if (this.itemSyncToShopBy === 'distributor' && this.selected_distributor_ids.length <= 0 && !allStoreChecked) {
          this.$notify.error('请选择要同步的店铺')
          this.itemsSyncToShopLoading = false;
          return false;
        }
        if (this.itemSyncToShopBy === 'dealer' && this.selected_dealer.length <= 0 && !allStoreChecked) {
          this.$notify.error('请选择要同步的经销商')
          this.itemsSyncToShopLoading = false;
          return false;
        }
        let requestParams = {};
        if (this.itemSyncToShopBy === 'distributor') {
          requestParams = {
            type: 'distributor',
            ids: this.selected_distributor_ids.join(',')
          };
        } else if (this.itemSyncToShopBy === 'dealer') {
          requestParams = {
            type: 'dealer',
            ids: this.selected_dealer_ids.join(',')
          };
        } else {
          this.$notify.error('未知类型')
          return;
        }
        requestParams.goods_ids = this.goods_id.join(',');

        requestParams.allStoreChecked = allStoreChecked;
        console.log('sync::::::::::',requestParams)
        console.log('sync::::::::::',requestParams)
        console.log('sync::::::::::',requestParams)

        itemSyncToDistributor(requestParams).then(response => {

          if (response.data.data.success == true) {
            this.$notify.success('已加入队列处理中');
            this.itemSyncToShopDrawer = false;
          } else {
            this.$notify.error('同步失败');
          }
          this.itemsSyncToShopLoading = false;
        }).catch(error => {
          this.itemsSyncToShopLoading = false;
        })
      },
      RegionChangeSearch(value) {
        var vals = getCascaderObj(value, this.regions)
        if (vals.length == 1) {
          this.itemSyncToShopParams.province = vals[0].label
          this.itemSyncToShopParams.city = ''
          this.itemSyncToShopParams.area = ''
        } else if (vals.length == 2) {
          this.itemSyncToShopParams.province = vals[0].label
          this.itemSyncToShopParams.city = vals[1].label
          this.itemSyncToShopParams.area = ''
        } else if (vals.length == 3) {
          this.itemSyncToShopParams.province = vals[0].label
          this.itemSyncToShopParams.city = vals[1].label
          this.itemSyncToShopParams.area = vals[2].label
        } else {
          this.itemSyncToShopParams.province = ''
          this.itemSyncToShopParams.city = ''
          this.itemSyncToShopParams.area = ''
        }
        this.itemSyncToShopParams.page = 1
        if (this.itemSyncToShopBy === 'distributor') {
          this.getDistributorEasyList();
        } else if (this.itemSyncToShopBy === 'dealer') {
          this.getDealerList();
        }
      },
      changeItemSyncToShopBy () {
        this.$refs.distributorTable.clearSelection();
        this.$refs.dealerTable.clearSelection();
        this.selected_distributor = [];
        this.selected_dealer = [];
        this.selected_distributor_ids = [];
        this.selected_dealer_ids = [];
        this.itemSyncToShopParams.dealerId = '';
        this.filter_dealer_list = [];
        if (this.itemSyncToShopBy === 'distributor') {
          this.getDistributorEasyList();
          // this.getDealerList({page:1, pageSize:20});
        } else if (this.itemSyncToShopBy === 'dealer') {
          this.getDealerList();
        }
      },
      changeDealerFilter () {
        this.itemSyncToShopParams.page = 1;
        this.getDistributorEasyList();
      },
      remoteSearchDealer (val) {
        this.dealerSearchLoading = true;
        let params = {
          page: 1,
          pageSize: 20,
          name: val,
        };
        this.getDealerList(params);
      },

      handleClosePreviewDialog() {
        this.previewVisible = false
        this.previewImgSrc = ''
      },

      handlePreview (row) {
        this.previewImgSrc = ''
        this.previewVisible = true
        this.imgLoading = true
        let params = {goods_id: row.goods_id, item_id:row.item_id};
        getGoodsPreviewQrCode(params).then(response => {
          this.previewImgSrc = 'data:image/png;base64,' + response.data.data.qrcode
          this.imgLoading = false
        }).catch(error => {
          this.imgLoading = false
        })
      },

      handleEduParams (row) {
        let params = {
          'goods_id': row.goods_id,
          'edu_activity_param': row.edu_activity_param,
        }
        updateEduItemsParam(params).then(response => {
          if (response.data.data.success === true) {
            this.$message.success('更新成功')
            this.getGoodsList()
          } else {
            this.$message.error('更新失败')
          }
        }) . catch(error => {
          this.$message.error('更新失败')
        })
      },
      goodsOmsinfo(item) {
        getGoodsOmsinfo({item_id: item.item_id}).then(() => {
          this.init()
        })
      },
      handleParamSetting(row) {
        const id = row.item_id
        useDialog(null, {
          title: `设置参数`,
          width: '60%',
          id,
          component: () => import('./param-setting.vue'),
          actions: [
            { label: '取消', key: 'close', size: 'default' },
            { label: '确定', key: 'save', type: 'primary', size: 'default' }
          ]
        }).then(arg => {
          if(arg) this.init()
        })
      },
      resetParams() {
        let params = {
          page: 1,
          pageSize: 20,
          item_type: 'normal',
          templates_id: '',
          keywords: '',
          category: 0,
          is_warning: false,
          tag_id: '',
          is_gift: 'all',
          type: 0,
          has_recommend: '',
          is_tcb: '',
          type_warehouse: '',
          auto_receiving: ''
        }
        this.params = params
        this.getGoodsList()
      }
  },
  // 页面缓存，返回是更新
  beforeRouteUpdate(to, from, next) {
    // 该钩子在路由更新时触发
    next();
    this.getGoodsList()
  },
  activated() {
    this.getGoodsList()
  },
    mounted () {
      this.init()
    },

    destroyed () {
      console.log(111)
    },
    watch: {
      '$route' (to, from) {
        this.init()
      },
      getStatus (val) {
        if (val) {
          this.getGoodsList()
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 90%;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 10px;
    img {
      width: 90%;
    }
  }
  .row-bg {
    padding: 10px 20px;
    background-color: #f9fafc;
  }
  .service-label .el-checkbox:first-child{
    margin-left: 15px;
  }
  .service-label .el-input:first-child{
    margin-left: 15px;
  }
  .grid-detail {
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .el-carousel {
    width: 375px;
  }
  .tag-users {
    padding-bottom: 10px;
    line-height: 1.2;
    .icon-user-circle1 {
        margin-right: 10px;
        font-size: 24px;
        color: #ccc;
    }
  }
  .selected-tags {
    padding-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    .label {
        margin-bottom: 15px;
    }
    .el-tag {
        margin-right: 10px;
        margin-bottom: 15px;
    }
  }
  .tag-item {
    cursor: pointer;
    color: #666;
    border-color: #dadadd;
    margin-right: 10px;
    margin-top: 10px;
  }
  .copy-btn {
    position: relative;
    [class^="copy-link"] {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      visibility: hidden;
    }
  }
</style>
<style lang="scss">
  .grid-detail {
    table, .detail-content-wrap, .detail-content-item {
      width: 100%!important;
    }
    img {
      width: 100%;
    }
  }
  .grid-attribute {
    table {
      width: 100%!important;
    }
  }
  .el-drawer__body {
    overflow: auto;
  }
</style>
