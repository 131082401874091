import fetch from '@/utils/fetch'

export const List = '/aftersales/repair/whiteList'

/** 导出 */
export function handleExport (query) {
  return fetch({
    url: List,
    method: 'get',
    params: query
  })
}

/** 批量删除 */
export function batchDelete (ids) {
  return fetch({
    url: '/aftersales/repair/whiteList',
    method: 'delete',
    params: {
      ids
    }
  })
}

/** 新增 */
export function cerate (data) {
  return fetch({
    url: '/aftersales/repair/whiteList',
    method: 'post',
    data
  })
}

/** 新增 */
export function update (data) {
  return fetch({
    url: '/aftersales/repair/whiteList',
    method: 'put',
    data
  })
}
