import { createSetting } from '@shopex/finder'

export const tableSetting = (vm) => createSetting({
  search: [],
  columns: [
    { name: '商品ID', key: 'item_id', width: 90 },
    { name: '商品货号', key: 'item_bn', minWidth: 150 },
    {
      name: '商品名称',
      key: 'item_name',
      minWidth: 150,
      render: (h, { row }) => {
        return (
          <div>
            { row.item_name }
            <div style="color: #8d8d8d">{ row.item_spec_desc }</div>
          </div>
        )
      }
    },
    // {
    //   name: '是否校验',
    //   key: 'isValidSwitch',
    //   minWidth: 120,
    //   headRender: (h) => {
    //     return (
    //       <div>
    //         是否校验
    //         <el-dropdown onCommand={vm.handleCommand}>
    //           <span style={{cursor: 'pointer', color: '#409EFF', 'margin-left': '12px' }}>
    //             批量处理<i class="el-icon-arrow-down el-icon--right"></i>
    //           </span>
    //           <el-dropdown-menu slot="dropdown">
    //             <el-dropdown-item command="1">开启</el-dropdown-item>
    //             <el-dropdown-item command="2">关闭</el-dropdown-item>
    //           </el-dropdown-menu>
    //         </el-dropdown>
    //       </div>
    //     )
    //   },
    //   render: (h, { row }) => {
    //     return (
    //       <el-switch
    //         value={row.isValidSwitch}
    //         active-value={true}
    //         inactive-value={false}
    //         onChange={v => {
    //           row.isValidSwitch = v
    //         }}
    //       >
    //       </el-switch>
    //     )
    //   }
    // }
  ],
  actions: [
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (rows) => {
          vm.handleDelete(rows)
        }
      }
    },
  ]
})
