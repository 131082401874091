<template>
  <div>
    <SpFinder
      ref="finder"
      row-key="id"
      :split-count="10"
      :url="table_url"
      :setting="setting"
      fixed-row-action
      reserve-selection
      row-actions-fixed-align="left"
      :hooks="{
        beforeSearch
      }"
    >
      <template #tableTop>
        <div class="table-top">
          <div>
            <el-tabs v-model="activeTab" type="card" @tab-click="handleClickTab">
              <el-tab-pane
                v-for="item in StatusOption"
                :key="item.value"
                :label="item.label"
                :name="item.value"
              />
            </el-tabs>
          </div>
        </div>
      </template>
    </SpFinder>
    <ImportDialog
      ref="importAddDialog"
      v-show="false"
      buttonName="批量导入"
      buttonSize="medium"
      fileName="批量导入"
      fileType="repair_white_list"
    />
  </div>
</template>
<script>
import { tableSetting, StatusOption } from './config'
import { List, handleExport } from './api'
import ImportDialog from '@/components/importDialog'

export default {
  components: {
    ImportDialog
  },
  data () {
    return {
      activeTab: StatusOption[0].value,
      table_url: List,
      StatusOption
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {
  },
  methods: {
    refresh() {
      this.$refs.finder.refresh()
    },
    handleClickTab() {
      this.refresh()
    },
    openImportAddDialog() {
      this.$refs.importAddDialog.visible = true
    },
    openExportDialog() {
      const searchparams = this.$refs.finder.getSearchParams()
      const selection = this.$refs.finder.$refs.finderTable.getSelection()
      const params = this.beforeSearch(searchparams)
      const exportParams = {
        ...params,
        is_export: 1,
        status_tab: this.activeTab,
        id: selection?.map(item => item.id)
      }
      handleExport(exportParams).then(_ => {
        this.$message.success('导出成功，请前往导出列表查看')
      })
    },
    beforeSearch(params) {
      const created_start = params.createTime && params.createTime[0]
      const created_end = params.createTime && params.createTime[1]
      delete params.createTime
      return {
        ...params,
        status_tab: this.activeTab,
        created_start,
        created_end
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .search-field-value {
  width: auto !important;
}
::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}
</style>

<style lang="scss">
.el-tooltip__popper {
  max-width: 80%;
}
</style>
