import fetch from '@/utils/fetch'

export const List = '/physicalgifts/list'

/** 删除商品 */
export function batchDelete (item_ids) {
  return fetch({
    url: `/physicalgifts`,
    method: 'delete',
    params: {
      item_ids
    }
  })
}

export function create (data) {
  return fetch({
    url: '/physicalgifts/save',
    method: 'post',
    data
  })
}

export function update (data) {
  return fetch({
    url: '/physicalgifts/update',
    method: 'put',
    data
  })
}
